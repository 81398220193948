import React from 'react';

import {Snackbar, SnackbarContent, IconButton} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';

import green from '@mui/material/colors/green';
import amber from '@mui/material/colors/amber';

import classNames from 'classnames';
import { withStyles } from '@mui/styles'

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const contentStyles = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

const NotificationSnackbar = props => {
    return (<Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        open={props.open}
        autoHideDuration={6000}
        onClose={props.handleClose}
        ClickAwayListenerProps={
            {mouseEvent: false}
        }>
        <NotificationContent
            variant={props.variant}
            message={props.message}
            onClose={props.handleClose}
        />
    </Snackbar>);
};

const NotificationContent =
    withStyles(contentStyles)(
        props => {
            const {classes, className, message, onClose, variant, ...other} = props;
            const Icon = variantIcon[variant];

            return (
                <SnackbarContent
                    className={classNames(classes[variant], className)}
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar" className={classes.message}>
                            <Icon className={classNames(classes.icon, classes.iconVariant)}/>
                            {message}
                        </span>
                    }
                    task={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={onClose}
                        >
                            <CloseIcon className={classes.icon}/>
                        </IconButton>,
                    ]}
                    {...other}
                />
            );
        });

export default withStyles(contentStyles)(NotificationSnackbar);
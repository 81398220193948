import axios from 'axios';
import appConfig from '../common/Config';

// =================================================================================
// Lookups
// =================================================================================
export const getActions = () => axios.get(`${appConfig.apiHost}/ffw/lookups/actions/`);

export const getHours = () => axios.get(`${appConfig.apiHost}/ffw/lookups/hours/`);

export const getNumberOfDrinks = () => axios.get(`${appConfig.apiHost}/ffw/lookups/numberofdrinks/`);

export const getGenders = () => axios.get(`${appConfig.apiHost}/ffw/lookups/genders/`);

export const getAlertness = () => axios.get(`${appConfig.apiHost}/ffw/lookups/alertness/`);

export const getShiftStartDates = () => axios.get(`${appConfig.apiHost}/ffw/lookups/shiftstartdates/`);

export const getShiftStartTimes = () => axios.get(`${appConfig.apiHost}/ffw/lookups/shiftstarttimes/`);

import React from 'react';

// Material UI
import {Typography, Grid, Button, Dialog, DialogActions, DialogTitle, DialogContent} from '@mui/material';
import { withStyles } from '@mui/styles'
import { DatePicker } from '@mui/x-date-pickers';
const styles = theme => ({
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(),
    },
    rightIcon: {
        marginLeft: theme.spacing(),
    },
    iconSmall: {
        fontSize: 20,
    },
});

const DateRangePickerDialog = props => {
    const { classes, title, startDate, endDate, onStartDateChange, onEndDateChange, onFilterClick, onFilterCloseClick } = props;

    return (<div>
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                variant="h6"
                                gutterBottom>
                                From Date:
                            </Typography>
                            <DatePicker
                                label="Start Date"
                                value={startDate}
                                onChange={onStartDateChange}
                                variant="static"
                                autoOk
                                clearable
                                fullWidth
                                openTo="day"
                                format="DD/MM/YYYY"
                                InputAdornmentProps={{position: "start"}}
                                inputVariant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                variant="h6"
                                gutterBottom>
                                To Date:
                            </Typography>
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={onEndDateChange}
                                variant="static"
                                autoOk
                                clearable
                                fullWidth
                                openTo="day"
                                format="DD/MM/YYYY"
                                InputAdornmentProps={{position: "start"}}
                                inputVariant="outlined"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="button"
                        className={classes.button}
                        variant="contained"
                        size="medium"
                        onClick={onFilterClick}
                    >
                        Filter
                    </Button>

                    <Button
                        type="button"
                        className={classes.button}
                        variant="contained"
                        size="medium"
                        onClick={onFilterCloseClick}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default withStyles(styles)(DateRangePickerDialog);
import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Drawer, IconButton, Divider, Box } from '@mui/material';
import Colours from './theme/Colours';

export default function SideBar(props) {
  const { theme, classes } = props;

  return (
    <Drawer
      variant={props.variant}
      anchor={theme.direction === 'rtl' ? 'right' : 'left'}
      open={props.open}
      onClose={props.onClose}
      classes={props.drawerClasses}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
    >
      <div className={classes.toolbar}>
        <div className={classes.logo}>{props.logo}</div>

        <IconButton
          sx={{
            '&.MuiIconButton-root': {
                color: '#fff'
              }
            }}
            onClick={props.onClose} size="large"
          >
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <Divider />
      {props.menuItems}

      {props.open && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            bottom: 0,
            textAlign: 'center',
            color: Colours.orange.secondary['500']
          }}
        >
          v{window.env.ADAL_CONFIG.app_ver}
        </Box>
      )}
    </Drawer>
  );
}

import React, { Component } from 'react';
// Material UI
import { StyledEngineProvider, CssBaseline, IconButton } from '@mui/material'; // Make sure you import ScopedCssBaseline first to avoid box-sizing conflicts as in the above example.
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Dashboard, InsertChartOutlined, Settings } from '@mui/icons-material';

import Routes, { navigationRoutes } from './Routes';
import { AppProvider, defaultContext } from './AppProvider';
import './App.css';

// Layout
import MainLayout from './components/layout/MainLayout';
import MenuItems from './components/layout/MenuItems';
import Colours from './components/layout/theme/Colours';

// Components Shared
import BHPLogo from './components/atoms/BHPLogo';
import LoggedInUser from './components/molecules/LoggedInUser/LoggedInUser';
import Reports from './containers/Reports';
import DashboardPage from './containers/Dashboard';

function Logo() {
  return (
    <div>
      <BHPLogo />
    </div>
  );
}

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultContext
    };
  }

  handleConfigChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  populateNavigation = () => {
    // All base routes as defined in Routes.js
    const allRoutes = navigationRoutes;

    // If user is a Manager or a Shift Supervisor show extra main menu items
    if (this.props.user.is_manager || this.props.user.is_supervisor) {
      // Reports Menu Item
      const reports = {
        path: '/reports',
        name: 'Reports',
        icon: InsertChartOutlined,
        component: Reports,
        selected: false,
        key: 'reports'
      };

      // Add Settings item to bottom of navigation menu
      // Prevent it from being added more than once.
      if (!allRoutes.some((e) => e.key === 'reports')) {
        allRoutes.push(reports);
      }

      // Dashboard Menu Item
      const dashboard = {
        path: '/dashboard',
        name: 'Dashboard',
        icon: Dashboard,
        component: DashboardPage,
        selected: true,
        key: 'dashboard'
      };

      // Add "Dashboard" item to 1st place in the navigation menu
      // Prevent it from being added more than once.
      if (!allRoutes.some((e) => e.key === 'dashboard')) {
        allRoutes.splice(0, 0, dashboard);
      }
    }

    return allRoutes;
  };

  // Replace the default `oresome` template.
  // Same as the template, but we add in the LoggedInUser component, passing it the logged in user details.
  TopBarActionsWithLoggedInUser = (props) => (
    <>
      <LoggedInUser user={this.props.user} />
      <IconButton color="inherit" onClick={props.toggleSettings}>
        <Settings />
      </IconButton>
    </>
  );

  render() {
    const theme = createTheme({
      palette: { ...Colours[this.state.colour], mode: this.state.type }
    });

    return (
      <AppProvider
        value={{
          ...this.state,
          handleConfigChange: this.handleConfigChange,

          // Pass in the user details so they can be used by context and are available to containers
          user: this.props.user
        }}
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <MainLayout
              menuItems={<MenuItems navigationRoutes={this.populateNavigation()} />}
              topBarActions={this.TopBarActionsWithLoggedInUser}
              logo={Logo}
              updateTheme={this.handleConfigChange}
              colour={this.state.colour}
              title="Fatigue Assessment Tool"
            >
              <Routes />
            </MainLayout>
          </ThemeProvider>
        </StyledEngineProvider>
      </AppProvider>
    );
  }
}

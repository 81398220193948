import React, {Component} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {forwardRef} from 'react';

// Context
import AppContext from '../AppProvider';

// Material UI
import { withStyles } from '@mui/styles';
import { Link, Grid, Paper, Tooltip, Typography, Button} from '@mui/material';
import { InfoOutlined, Edit } from "@mui/icons-material";
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';

// Utils
import MaterialTable from '@material-table/core';
import axios from 'axios';
import * as moment from "moment";

// Components Shared
import Preloader from '../components/atoms/PreLoader/Preloader';
import RiskRatingScoreChip from '../components/atoms/RiskRatingScoreChip';
import DateRangePickerDialog from '../components/organisms/DateRangePickerDialog';
import ActionRequiredCountChip from "../components/atoms/ActionRequiredCountChip";
import WidgetBarChartAssessmentsByRiskRatingForDateRange from "../components/organisms/WidgetBarChartAssessmentsByRiskRatingForDateRange";

// API Services
import {getAssessments} from "../api/AssessmentService";
import {getAssessmentsCountByEmployeeByRiskRatingForManager} from "../api/WidgetService";
import dayjs from 'dayjs';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 420,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
});

class ReportDirectReportsAssessments extends Component {
    static contextType = AppContext;

    state = {
        isLoading: true,

        isDateRangePickerOpen: false,

        startDate: moment(new Date()).subtract(7,'days').format("YYYY-MM-DD"),
        endDate: moment(new Date()).add(0,'days').format("YYYY-MM-DD"),
    };

    handleSetStartDate = (value) => {
        this.setState(() => ({
            startDate: moment(value).format("YYYY-MM-DD"),
        }))
    };

    handleSetEndDate = (value) => {
        this.setState(() => ({
            endDate: moment(value).format("YYYY-MM-DD"),
        }))
    };

    handleClickDateRangePickerOpen = () => {
        this.setState(() => ({
            isDateRangePickerOpen: true,
        }))
    };

    handleClickDateRangePickerFilter = () => {
        this.setState(() => ({
            isDateRangePickerOpen: false,
        }));
        this.handleGetAssessments();
        this.handleGetAssessmentsCountByEmployeeByRiskRatingForManager();
    };

    handleClickDateRangePickerClose = () => {
        this.setState(() => ({
            isDateRangePickerOpen: false,
        }));
    };

    // ===================================================================================================
    // Widgets data
    // ===================================================================================================
    handleGetAssessmentsCountByEmployeeByRiskRatingForManager = () => {

        this.setState({
            // Clear the data, causing the 'loading icon' to show
            assessmentsCountByEmployeeByRiskRatingForDateRangeForManager: null,
        });

        let queryPayload = {
            params: {
                start_date: this.state.startDate,
                end_date: this.state.endDate,
            }
        };

        axios.all(
            [
                getAssessmentsCountByEmployeeByRiskRatingForManager(queryPayload),
            ])
            .then(axios.spread((assessmentsCountByEmployeeByRiskRatingForDateRangeForManager) => {
                this.setState({
                    assessmentsCountByEmployeeByRiskRatingForDateRangeForManager: assessmentsCountByEmployeeByRiskRatingForDateRangeForManager.data,
                });
            }))
    };

    handleGetAssessments = () => {
        let queryPayload = {
            params: {
                filter_type: 'ReportDirectReportsAssessments',
                start_date: this.state.startDate,
                end_date: this.state.endDate,
            }
        };

        axios.all(
            [
                getAssessments(queryPayload),
            ])
            .then(axios.spread((assets) => {
                    this.setState({
                        assessments: assets.data.results,
                        isLoading: false,
                    });
                })
            )
    };

    componentDidMount() {
        this.handleGetAssessments();
        this.handleGetAssessmentsCountByEmployeeByRiskRatingForManager();
    };

    render() {
        // Show loading icon while waiting for results from backend
        if (this.state.isLoading) return (
            <Grid item xs={12}>
                <Paper>
                    <Preloader/>
                </Paper>
            </Grid>
        );

        const loggedInUser = this.context.user;
        const { assessments, startDate, endDate, isDateRangePickerOpen, assessmentsCountByEmployeeByRiskRatingForDateRangeForManager } = this.state;
        const { classes } = this.props;

        const startDateAsText = moment(startDate).format("DD/MM/YYYY");
        const endDateAsText = moment(endDate).format("DD/MM/YYYY");

        return (
            <React.Fragment>
                {(isDateRangePickerOpen) &&
                    <DateRangePickerDialog
                        startDate={dayjs(startDate)}
                        endDate={dayjs(endDate)}
                        onStartDateChange={this.handleSetStartDate}
                        onEndDateChange={this.handleSetEndDate}
                        onFilterClick={this.handleClickDateRangePickerFilter}
                        onFilterCloseClick={this.handleClickDateRangePickerClose}
                    />
                }

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} align={"left"}>
                        <Typography variant="caption" display={"inline"}>
                            Date Range:&nbsp;
                        </Typography>
                        <Typography variant="subtitle1" display={"inline"}>
                            &nbsp;
                            {moment(startDate).format("ll")} -> {moment(endDate).format("ll")}
                            &nbsp;
                            <Button variant="outlined" color="secondary" onClick={this.handleClickDateRangePickerOpen}>
                                <Edit/>
                            </Button>
                        </Typography>
                    </Grid>

                     <Grid item xs={12} sm={12}>
                        <WidgetBarChartAssessmentsByRiskRatingForDateRange
                            data={assessmentsCountByEmployeeByRiskRatingForDateRangeForManager}
                            tableTitleTooltipText={
                                <React.Fragment>
                                    Shows Assessments completed by employees where you are their direct manager.
                                    <ul>
                                        <li><b>{'Assessments Criteria:'}</b>{' '}
                                            <ul>
                                                <li><b>{'Employees Manager = You'}</b>{' '}</li>
                                                <li><b>{'Shift Date (Range) = ' + startDateAsText + ' to ' + endDateAsText}</b>.{' '}</li>
                                                <li><b>{'Risk Rating = Low, Medium, High'}</b>{' '}</li>
                                            </ul>
                                        </li>
                                        <li><b>{'Grouped By:'}</b>{' '}
                                            <ul>
                                                <li><b>{'Employee'}</b>{' '}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </React.Fragment>
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <MaterialTable
                            columns={[
                                {
                                    title: 'Employee',
                                    field: 'user_full_name',
                                    defaultGroupOrder: 1,
                                    // Note: rowdata only contains the label when grouping
                                    render: (rowData, group) => {
                                        if (group === "group"){
                                            // Use different values when grouped
                                            return rowData
                                        }
                                        else {
                                            if (loggedInUser.is_manager) {
                                                return <Link component={RouterLink} to={"/userprofile/" + rowData.user_id}>{rowData.user_full_name} ({rowData.user_email})</Link>
                                            }
                                            else {
                                                return <React.Fragment>{rowData.user_full_name} ({rowData.user_email})</React.Fragment>
                                            }
                                        }
                                    }
                                },
                                {
                                    title: 'Location',
                                    field: 'depot'
                                },
                                {
                                    title: 'Shift Date',
                                    field: 'shift_start_date',
                                },
                                {
                                    title: 'Shift Time (24h)',
                                    field: 'shift_start_time'
                                },
                                {
                                    title: 'Shift Supervisor',
                                    field: 'shift_supervisor_full_name',
                                    render: (rowData, group) => {
                                        if (group === "group"){
                                            // Use different values when grouped
                                            return rowData
                                        }
                                        else {
                                            return rowData.shift_supervisor_full_name + " (" + rowData.shift_supervisor_email + ")"
                                        }
                                    }
                                },
                                {
                                    title: 'Risk',
                                    field: 'risk_rating',
                                    // Note: rowdata only contains the label when grouping
                                    render: (rowData, group) => {
                                        if (group === "group"){
                                            // Use different values when grouped
                                            return <RiskRatingScoreChip
                                                size="small"
                                                label={rowData}
                                                RiskRatingName={rowData}
                                            />
                                        }
                                        else {
                                            return <RiskRatingScoreChip
                                                size="small"
                                                label={rowData.risk_rating}
                                                RiskRatingName={rowData.risk_rating}
                                                RiskScore={rowData.risk_score}
                                            />
                                        }
                                    }
                                },
                                {
                                    title: 'Action Taken',
                                    field: 'action_taken', // custom field name (not in data model)
                                    grouping: false, // Disable grouping
                                    sorting: false,
                                    render: rowData => {
                                        let displayVal = "";

                                        // Check if any actions have been recorded for this assessment
                                        if (rowData.actions.length > 0) {
                                            // Display the action name taken
                                            if (rowData.actions[0].action_required_name) {
                                                displayVal = rowData.actions[0].action_required_name;
                                            }
                                        }
                                        // If risk rating is low then no action is required
                                        else if (rowData.risk_rating === "Low") {
                                            displayVal = "No action required";
                                        }
                                        else {
                                            return <ActionRequiredCountChip
                                                size={"small"}
                                                label={"Action required"}
                                            />
                                        }
                                        return displayVal
                                    }
                                },
                                {
                                    title: '',
                                    field: 'action',  // custom field name (not in data model)
                                    grouping: false, // Disable grouping
                                    render: rowData => {
                                        return <Link component={RouterLink} to={"/assessment/" + rowData.id}>View</Link>
                                    }
                                },
                            ]}
                            data={assessments}
                            title={
                                <React.Fragment>
                                    <div>
                                        <Typography variant="h6">
                                            My Direct Reports Assessments
                                            <Tooltip
                                                classes={{
                                                    tooltip: classes.htmlTooltip,
                                                }}
                                                title={
                                                    <React.Fragment>
                                                        Shows Fatigue Assessments completed by employee where you are their direct manager.
                                                        <ul>
                                                            <li><b>{'Criteria:'}</b>{' '}
                                                                <ul>
                                                                    <li><b>{'Employees Manager = You'}</b>{' '}</li>
                                                                    <li><b>{'Shift Date (Range) = ' + startDateAsText + ' to ' + endDateAsText}</b>{' '}</li>
                                                                    <li><b>{'Risk Rating = Low, Medium, High'}</b>{' '}</li>
                                                                </ul>
                                                            </li>
                                                            <li><b>{'Grouped By (default):'}</b>{' '}
                                                                <ul>
                                                                    <li><b>{'Employee'}</b>{' '}</li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </React.Fragment>
                                                }
                                            >
                                                <InfoOutlined color='disabled'/>
                                            </Tooltip>
                                        </Typography>
                                    </div>
                                </React.Fragment>
                            }
                            icons={tableIcons}
                            options={{
                                grouping: true,
                                defaultExpanded: true,
                                pageSize: 50,
                                pageSizeOptions: [20, 50, 100, 200],
                                emptyRowsWhenPaging: false,
                            }}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(ReportDirectReportsAssessments);
const Colours = {
    orange: {
      primary: {
        50: '#fbebe0',
        100: '#f5cdb3',
        200: '#eeac80',
        300: '#e78b4d',
        400: '#e27226',
        500: '#dd5900',
        600: '#d95100',
        700: '#d44800',
        800: '#cf3e00',
        900: '#c72e00',
        A100: '#fff1ef',
        A200: '#ffc7bc',
        A400: '#ff9c89',
        A700: '#ff876f',
        contrastDefaultColor: 'light'
      },
      secondary: {
        50: '#fef0e0',
        55: '#f1c40f',
        100: '#fdd9b3',
        200: '#fbbf80',
        300: '#f9a54d',
        400: '#f89226',
        500: '#f77f00',
        600: '#f67700',
        700: '#f56c00',
        800: '#f36200',
        900: '#f14f00',
        A100: '#ffffff',
        A200: '#ffece5',
        A400: '#ffc6b2',
        A700: '#ffb398',
        contrastDefaultColor: 'dark'
      }
    },
    blue: {
      primary: {
        50: '#e4eaf2',
        100: '#bbcade',
        200: '#8ea7c8',
        300: '#6184b2',
        400: '#3f69a2',
        500: '#1d4f91',
        600: '#1a4889',
        700: '#153f7e',
        800: '#113674',
        900: '#0a2662',
        A100: '#95b1ff',
        A200: '#628cff',
        A400: '#2f66ff',
        A700: '#1553ff',
        contrastDefaultColor: 'light'
      },
      secondary: {
        50: '#e2edf6',
        100: '#b6d3e9',
        200: '#85b6da',
        300: '#5498cb',
        400: '#2f82bf',
        500: '#0a6cb4',
        600: '#0964ad',
        700: '#0759a4',
        800: '#054f9c',
        900: '#033d8c',
        A100: '#b8d1ff',
        A200: '#85b0ff',
        A400: '#5290ff',
        A700: '#397fff',
        contrastDefaultColor: 'light'
      }
    },
    green: {
      primary: {
        50: '#e1eceb',
        100: '#b3cecc',
        200: '#81aeaa',
        300: '#4f8e88',
        400: '#29756f',
        500: '#035d55',
        600: '#03554e',
        700: '#024b44',
        800: '#02413b',
        900: '#01302a',
        A100: '#69ffe8',
        A200: '#36ffe0',
        A400: '#03ffd9',
        A700: '#00e8c5',
        contrastDefaultColor: 'light'
      },
      secondary: {
        50: '#e1f5f2',
        100: '#b4e6e0',
        200: '#83d6cb',
        300: '#51c6b6',
        400: '#2bb9a6',
        500: '#06ad96',
        600: '#05a68e',
        700: '#049c83',
        800: '#039379',
        900: '#028368',
        A100: '#b0ffeb',
        A200: '#7dffdf',
        A400: '#4affd2',
        A700: '#30ffcc',
        contrastDefaultColor: 'light'
      }
    },
    purple: {
      primary: {
        50: '#ede7ed',
        100: '#d1c2d1',
        200: '#b399b3',
        300: '#947094',
        400: '#7d527d',
        500: '#663366',
        600: '#5e2e5e',
        700: '#532753',
        800: '#492049',
        900: '#381438',
        A100: '#ff77ff',
        A200: '#ff44ff',
        A400: '#ff11ff',
        A700: '#f600f6',
        contrastDefaultColor: 'light'
      },
      secondary: {
        50: '#f3e7f3',
        100: '#e0c2e0',
        200: '#cc99cc',
        300: '#b870b8',
        400: '#a852a8',
        500: '#993399',
        600: '#912e91',
        700: '#862786',
        800: '#7c207c',
        900: '#6b146b',
        A100: '#ffa2ff',
        A200: '#ff6fff',
        A400: '#ff3cff',
        A700: '#ff23ff',
        contrastDefaultColor: 'light'
      }
    },
    turquoise: {
      primary: {
        50: '#e1eaec',
        100: '#b3cbcf',
        200: '#80a8af',
        300: '#4d858f',
        400: '#276b77',
        500: '#01515f',
        600: '#014a57',
        700: '#01404d',
        800: '#013743',
        900: '#002732',
        A100: '#6ad4ff',
        A200: '#37c6ff',
        A400: '#04b7ff',
        A700: '#00a7ea',
        contrastDefaultColor: 'light'
      },
      secondary: {
        50: '#e0f0f3',
        100: '#b3d8e0',
        200: '#80bfcc',
        300: '#4da5b7',
        400: '#2691a7',
        500: '#007e98',
        600: '#007690',
        700: '#006b85',
        800: '#00617b',
        900: '#004e6a',
        A100: '#99dfff',
        A200: '#66cfff',
        A400: '#33c0ff',
        A700: '#1ab8ff',
        contrastDefaultColor: 'light'
      }
    }
  };
  
  export default Colours;
  
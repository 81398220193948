import React from 'react';
import {withRouter} from 'react-router-dom';

// Material UI
import { withStyles } from '@mui/styles'
import Chip from '@mui/material/Chip';
import WarningIcon from '@mui/icons-material/Warning';


// Styling overrides - used to override and set the styling/css of the page
const styles = theme => ({
    actionRequired: {
        'borderColor': '#d9534f',
        'color': '#d9534f',
    },
});

class ActionRequiredCountChip extends React.Component {

    render() {

        const { classes, label, size, countRequiringAction } = this.props;

        const getChipStyle = () => {
            let chipClass;
            chipClass = classes.actionRequired;
            return chipClass
        };

        const getLabelText = () => {
            let labelText = label;

            // Show as summary. ie. "4 require action"
            if (countRequiringAction) {
                labelText = countRequiringAction + ' ' + labelText.toUpperCase();
            }
            else {
                 labelText = labelText.toUpperCase();
            }

            return labelText
        };

        return (
            <Chip
                size={size}
                icon={<WarningIcon style={{ color: "#d9534f" }}/>}
                label={getLabelText()}
                variant="outlined"
                className={getChipStyle()}
            />
        );
    }
};

export default withRouter(withStyles(styles)(ActionRequiredCountChip));
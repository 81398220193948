import React from 'react';
import { withRouter } from 'react-router-dom';

// Material UI
import { withStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableRow } from '@mui/material'

// Components Shared
import ControlsChip from '../atoms/ControlsChip';

// Utils
import * as moment from 'moment';

// Styling overrides - used to override and set the styling/css of the page
const styles = theme => ({
});

class UserData extends React.Component {

    render() {

        const { action } = this.props;

        return (
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell style={{width: '50%'}}>
                            Action Taken:
                        </TableCell>
                        <TableCell>
                            { action.action_required_name }
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{width: '50%'}}>
                            Controls:
                        </TableCell>
                        <TableCell style={{width: '50%'}}>
                            {(action.controls_as_list && action.controls_as_list.length > 0) &&
                                <React.Fragment>
                                    {action.controls_as_list.map((control, index) => (
                                        <React.Fragment key={index}>
                                            <ControlsChip
                                                label={control}
                                            />
                                            &nbsp;
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            }
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{width: '50%'}}>
                            Comments:
                        </TableCell>
                        <TableCell style={{width: '50%'}}>
                            { action.comments }
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{width: '50%'}}>
                            Controls Implemented By:
                        </TableCell>
                        <TableCell style={{width: '50%'}}>
                            { action.controls_implemented_by_email }
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{width: '50%'}}>
                            Supervisor Signature:
                        </TableCell>
                        <TableCell style={{width: '50%'}}>
                            { action.supervisor_signature }
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{width: '50%'}}>
                            Timestamp:
                        </TableCell>
                        <TableCell>
                            { moment(action.timestamp).format("MMMM Do YYYY, h:mm:ss a") }
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }
}

export default withRouter(withStyles(styles)(UserData));
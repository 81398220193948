import {createContext} from 'react';

export const defaultContext = {
  colour: 'orange',
  type: 'light',
  user: null,
  handleConfigChange: () => {
  }
};

// This is used to allow us to pass data down to our containers.
const AppContext = createContext(defaultContext);

export const AppProvider = AppContext.Provider;
export const AppConsumer = AppContext.Consumer;
export default AppContext;

import React, {Component} from 'react';

// Material UI
import {
    Table,
    TableRow,
    TableCell, TableBody
} from '@mui/material';
import { withStyles } from '@mui/styles';

// Components Shared
import AssessmentsTableHierarchy from './AssessmentsTableHierarchy';

const styles = theme => ({
});

class ManagerHierarchyTable extends Component {

    render() {

        const { rowData } = this.props;

        return (
            <React.Fragment>
                {/* Check if direct reports exist for this user */}
                {(rowData.directreports.length > 0) &&
                <Table size="small">
                    <TableBody>
                        <TableRow>

                            {/* Root user in management hierarchy */}
                            <TableCell component="th" scope="row" style={{'backgroundColor': 'grey'}}>
                                <div style={{'writingMode': 'vertical-rl', 'WebkitWritingMode': 'vertical-rl'}}>
                                    {rowData.full_name} ({rowData.email})
                                </div>
                            </TableCell>

                            <TableCell>
                                {/* Show 'Material-Table' containing any assessments completed by direct reports of root user */}
                                <AssessmentsTableHierarchy manager={rowData}/>

                                {
                                    <React.Fragment>
                                        <Table size="small">
                                            <TableBody>
                                                {/* Loop through root users direct reports */}
                                                {rowData.directreports.map((user, index) => (
                                                    <React.Fragment key={index}>
                                                        {/* If direct report is a Manager then repeat above */}
                                                        {(user.is_manager === true) &&

                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row" style={{'backgroundColor': 'grey'}}>
                                                                    <div style={{'writingMode': 'vertical-rl'}}>
                                                                        {user.full_name} ({user.email})
                                                                    </div>

                                                                </TableCell>
                                                                <TableCell>
                                                                    {/* Show 'Material-Table' containing any assessments completed by direct reports of user */}
                                                                    <AssessmentsTableHierarchy manager={user}/>

                                                                    {
                                                                        // If user has direct reports, the loop this component.
                                                                        (user.directreports.length > 0) &&

                                                                            <React.Fragment>
                                                                                {/* Loop through root users direct reports */}
                                                                                {user.directreports.map((user, index) => (
                                                                                    <ManagerHierarchyTable rowData={user} key={index}/>
                                                                                    ))
                                                                                }
                                                                            </React.Fragment>
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        }
                                                    </React.Fragment>
                                                ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </React.Fragment>
                                }
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
                }
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(ManagerHierarchyTable);
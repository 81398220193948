import React from 'react';
import { CircularProgress } from '@mui/material';

// Material UI
import {Grid} from '@mui/material';

class Preloader extends React.Component {

    render() {
        return (
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                <Grid item xs={3}>
                    <CircularProgress style={{'color': '#dd5900'}} size={120} />
                </Grid>
            </Grid>
        );
    }
};

export default Preloader; 
import React, {Component} from 'react';

// Context
import AppContext from '../AppProvider';

// Material UI
import { withStyles } from '@mui/styles'
import { Grid, Paper, Tooltip, Typography, Button } from '@mui/material';
import { InfoOutlined } from "@mui/icons-material";
import Edit from '@mui/icons-material/Edit';

// Utils
import axios from 'axios';
import * as moment from "moment";

// Components Shared
import Preloader from '../components/atoms/PreLoader/Preloader';
import ManualHierarchy from '../components/organisms/ManagerHierarchyTable';
import WidgetChartMyAllReportsAssessmentsByRiskRatingForDateRange from "../components/organisms/WidgetChartMyAllReportsAssessmentsByRiskRatingForDateRange";
import WidgetChartAllReportsTree from "../components/organisms/WidgetChartAllReportsTree";
import DateRangePickerDialog from "../components/organisms/DateRangePickerDialog";

// API Services
import {getAssessmentsByHierarchy} from "../api/UserService";
import dayjs from 'dayjs';

const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 420,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
});

class ReportAssessmentsByHierarchy extends Component {
    static contextType = AppContext;

    state = {
        isLoading: true,

        isDateRangePickerOpen: false,

        startDate: moment(new Date()).subtract(30,'days').format("YYYY-MM-DD"),
        endDate: moment(new Date()).add(0,'days').format("YYYY-MM-DD"),
    };

    handleSetStartDate = (value) => {
        this.setState(() => ({
            startDate: moment(value).format("YYYY-MM-DD"),
        }))
    };

    handleSetEndDate = (value) => {
        this.setState(() => ({
            endDate: moment(value).format("YYYY-MM-DD"),
        }))
    };

    handleClickDateRangePickerOpen = () => {
        this.setState(() => ({
            isDateRangePickerOpen: true,
        }))
    };

    handleClickDateRangePickerFilter = () => {
        this.setState(() => ({
            isDateRangePickerOpen: false,
        }));
        this.handleGetAssessments();
    };

    handleClickDateRangePickerClose = () => {
        this.setState(() => ({
            isDateRangePickerOpen: false,
        }));
    };

    handleGetAssessments = () => {
        this.setState({
            isLoading: true,
        });

        let queryPayload = {
            params: {
                start_date: this.state.startDate,
                end_date: this.state.endDate,
            }
        };

        axios.all(
            [
                getAssessmentsByHierarchy(queryPayload),
            ])
            .then(axios.spread((managementHierarchy) => {
                    this.setState({
                        managementHierarchy: managementHierarchy.data,
                        isLoading: false,
                    });
                })
            )
    };

    componentDidMount() {
        this.handleGetAssessments();
    };

    render() {

        // Show loading icon while waiting for results from backend
        if (this.state.isLoading) return (
            <Grid item xs={12}>
                <Paper>
                    <Preloader/>
                </Paper>
            </Grid>
        );

        const { managementHierarchy, isLoading, startDate, endDate, isDateRangePickerOpen } = this.state;
        const {classes} = this.props;

        const startDateAsText = moment(startDate).format("DD/MM/YYYY");
        const endDateAsText = moment(endDate).format("DD/MM/YYYY");

        return (
            <React.Fragment>
                {(isDateRangePickerOpen) &&
                    <DateRangePickerDialog
                        startDate={dayjs(startDate)}
                        endDate={dayjs(endDate)}
                        onStartDateChange={this.handleSetStartDate}
                        onEndDateChange={this.handleSetEndDate}
                        onFilterClick={this.handleClickDateRangePickerFilter}
                        onFilterCloseClick={this.handleClickDateRangePickerClose}
                    />
                }

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} align={"left"}>
                        <Typography variant="caption" display={"inline"}>
                            Date Range:&nbsp;
                        </Typography>
                        <Typography variant="subtitle1" display={"inline"}>
                            &nbsp;
                            {moment(startDate).format("ll")} -> {moment(endDate).format("ll")}
                            &nbsp;
                            <Button variant="outlined" color="secondary" onClick={this.handleClickDateRangePickerOpen}>
                                <Edit/>
                            </Button>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <WidgetChartMyAllReportsAssessmentsByRiskRatingForDateRange
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <WidgetChartAllReportsTree
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography className="page-title" variant="h6">
                            Assessments by Organisational Hierarchy:
                            <Tooltip
                                classes={{
                                    tooltip: classes.htmlTooltip,
                                }}
                                title={
                                    <React.Fragment>
                                        Shows Assessments completed by employees below you in your management heirarchy.
                                        <ul>
                                            <li><b>{'Criteria:'}</b>{' '}
                                                <ul>
                                                    <li><b>{'Employees Manager = You or a manager below you in the organisational hierarchy'}</b>{' '}</li>
                                                    <li><b>{'Shift Date (Range) = ' + startDateAsText + ' to ' + endDateAsText}</b>{' '}</li>
                                                </ul>
                                            </li>
                                            <li><b>{'Grouped By (default):'}</b>{' '}
                                                <ul>
                                                    <li><b>{'Shift Date'}</b>{' '}</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </React.Fragment>
                                }
                            >
                                <InfoOutlined color='disabled'/>
                            </Tooltip>
                        </Typography>
                    </Grid>

                    { (isLoading) ? (
                        <Grid item xs={12}>
                            <Paper className={classes.root}>
                                <Preloader/>
                            </Paper>
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Paper className={classes.root}>
                                        <React.Fragment>
                                            {(managementHierarchy.directreports.length > 0) &&
                                                <ManualHierarchy
                                                    rowData={managementHierarchy}
                                                />
                                            }
                                        </React.Fragment>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(ReportAssessmentsByHierarchy);
import React, {Component} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {forwardRef} from 'react';

// Material UI
import { withStyles } from '@mui/styles';
import { Link, Grid, Paper, Tooltip, Typography, Button } from '@mui/material';
import {InfoOutlined} from "@mui/icons-material";
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import RefreshIcon from '@mui/icons-material/Refresh';

// Utils
import * as moment from "moment";
import MaterialTable from '@material-table/core';

// Components Shared
import Preloader from '../atoms/PreLoader/Preloader';
import RiskRatingScoreChip from '../atoms/RiskRatingScoreChip';
import ActionRequiredCountChip from "../atoms/ActionRequiredCountChip";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const styles = theme => ({
    root: {
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },
        paddingLeft: theme.spacing(0),
    },
    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 420,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
});

class AssessmentsTableUserProfile extends Component {

    componentDidMount() {
        this.props.handleGetAssessments()
    };

    render() {
        const { classes, isLoadingAssessments, assessments, handleGetAssessments, startDate, endDate, userProfile } = this.props;

        // Show loading icon while waiting for results from backend
        if (isLoadingAssessments) return (
            <Grid item xs={12} className={classes.root}>
                <Paper>
                    <Preloader/>
                </Paper>
            </Grid>
        );

        const startDateAsText = moment(startDate).format("DD/MM/YYYY");
        const endDateAsText = moment(endDate).format("DD/MM/YYYY");

        return (

            <Grid item xs={12} className={classes.root}>
                <MaterialTable
                    columns={[
                        {
                            title: 'Shift Supervisor',
                            field: 'shift_supervisor_full_name',
                            render: (rowData, group) => {
                                if (group === "group"){
                                    // Use different values when grouped
                                    return rowData
                                }
                                else {
                                    return rowData.shift_supervisor_full_name + " (" + rowData.shift_supervisor_email + ")"
                                }
                            }
                        },
                        {
                            title: 'Location',
                            field: 'depot'
                        },
                        {
                            title: 'Shift Date',
                            field: 'shift_start_date',
                            defaultSort: 'desc',
                        },
                        {
                            title: 'Shift Time (24h)',
                            field: 'shift_start_time'
                        },
                        {
                            title: 'Risk',
                            field: 'risk_rating',
                            // Note: rowdata only contains the label when grouping
                            render: (rowData, group) => {
                                if (group === "group"){
                                    // Use different values when grouped
                                    return <RiskRatingScoreChip
                                        size="small"
                                        label={rowData}
                                        RiskRatingName={rowData}
                                    />
                                }
                                else {
                                    return <RiskRatingScoreChip
                                        size="small"
                                        label={rowData.risk_rating}
                                        RiskRatingName={rowData.risk_rating}
                                        RiskScore={rowData.risk_score}
                                    />
                                }
                            }
                        },
                        {
                            title: 'Action Taken',
                            field: 'action_taken', // custom field name (not in data model)
                            grouping: false,
                            sorting: false,
                            render: (rowData, group) => {
                                let displayVal = "";

                                // Check if any actions have been recorded for this assessment
                                if (rowData.actions.length > 0) {
                                    // Display the action name taken
                                    if (rowData.actions[0].action_required_name) {
                                        displayVal = rowData.actions[0].action_required_name;
                                    }
                                }
                                // If risk rating is low then no action is required
                                else if (rowData.risk_rating === "Low") {
                                    displayVal = "No action required";
                                } else {
                                    return <ActionRequiredCountChip
                                        size={"small"}
                                        label={"Action required"}
                                    />
                                }
                                return displayVal
                            }
                        },
                        {
                            title: '',
                            field: 'action',  // custom field name (not in data model)
                            render: rowData => {
                                return <Link component={RouterLink} to={"/assessment/" + rowData.id}>View</Link>
                            }
                        },
                    ]}
                    data={assessments}
                    title={
                        <React.Fragment>
                            <div>
                            <Typography variant="h6">
                                Fatigue Assessments
                                <Tooltip
                                    classes={{
                                        tooltip: classes.htmlTooltip,
                                    }}
                                    title={
                                        <React.Fragment>
                                            <b>{'Assessments completed by ' + userProfile.fullname + '(' + userProfile.email + ')'}</b>{' '}
                                            <br/>
                                            <ul>
                                                <li><b>{'Assessments Criteria:'}</b>{' '}
                                                    <ul>
                                                        <li><b>{'Shift Dates = ' + startDateAsText + ' to ' + endDateAsText}</b>{' '}</li>
                                                        <li><b>{'Risk Rating = Low, Medium, High'}</b>{' '}</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </React.Fragment>
                                    }
                                >
                                    <InfoOutlined color='disabled'/>
                                </Tooltip>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    onClick={() => { handleGetAssessments(); }}
                                >
                                    <Tooltip
                                        classes={{
                                            tooltip: classes.htmlTooltip,
                                        }}
                                        title={
                                            <React.Fragment>
                                                <b>{'Refresh'}</b>{' '}
                                                <br/>
                                            </React.Fragment>
                                        }
                                    >
                                        <RefreshIcon/>
                                    </Tooltip>
                                </Button>
                            </Typography>
                            </div>
                        </React.Fragment>
                    }
                    icons={tableIcons}
                    options={{
                        grouping: true,
                        sorting: true,
                        defaultExpanded: true,
                        pageSize: 50,
                        pageSizeOptions: [20, 50, 100, 200],
                        emptyRowsWhenPaging: false,
                    }}
                />
            </Grid>
        )
    }
}

export default withStyles(styles)(AssessmentsTableUserProfile);
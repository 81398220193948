import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Material UI
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Grid, Table, TableRow, TableBody, TableCell, TableHead } from '@mui/material';

// Context
import AppContext from '../AppProvider';

// Components Shared
import NotificationSnackbar from '../components/molecules/NotificationSnackbar/NotificationSnackbar';
import Preloader from '../components/atoms/PreLoader/Preloader';
import RiskRatingScoreChip from '../components/atoms/RiskRatingScoreChip';
import IndividualFieldRiskRatingChip from '../components/molecules/IndividualFieldRiskRatingChip';
import AssessmentDetailsChip from '../components/atoms/AssessmentDetailsChip';
import RecommendedActions from '../components/organisms/RecommendedActions';
import UserData from '../components/organisms/UserData';
import ActionTaken from '../components/molecules/ActionTaken';
import FatigueAssessmentActionForm from '../components/organisms/FatigueAssessmentActionForm';

// Utils
import axios from 'axios';

// API Services
import { getAssessment } from '../api/AssessmentService';
import { getActions } from "../api/LookupsService";


// Styling overrides - used to override and set the styling/css of the page
const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        chip: {
            margin: theme.spacing(),
        },
    },
    badge: {
        top: '45%',
        right: -15,
    },
    column: {
        flex: 1,
    },
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{padding: 8 * 3}}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class AssessmentDetails extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            isDataLoaded: false,

            notificationDisplayed: false,
            notificationMessage: "",
            notificationVariant: "",
        }
    }

    // ===================================================================================================
    // Notifications
    // ===================================================================================================
    displayNotification = variant => message => {
        this.setState({
            notificationDisplayed: true,
            notificationMessage: message,
            notificationVariant: variant
        });
    };

    handleCloseNotification = () => {
        this.setState({
            notificationDisplayed: false
        });
    };

    componentDidMount() {
        this.handleGetAssessment();
    }

    handleGetAssessment = () => {
        // ---- Get Fatigue Assessment ID from URL params & get its details from API ----------------------------------------------
        const {match} = this.props;

        if (match && match.params && match.params.assessmentId) {

            const assessmentId = match.params.assessmentId;
            axios.all(
                [
                    getAssessment(assessmentId),
                    getActions(),
                ])
                .then(axios.spread((assessment, actions) => {
                        this.setState({
                            assessment: {...assessment.data},
                            lookups: {
                                actions: [...actions.data],
                            },
                            isDataLoaded: true,
                        });
                    })
                )
        }

        if (this.props.location.state && this.props.location.state.notificationMessage !== "") {
            this.displayNotification(this.props.location.state.notificationVariant)(this.props.location.state.notificationMessage);
            this.props.location.state = null;
        }
    };

    render() {
        const loggedInUser = this.context.user;

        // Display loading icon until all required data has been gathered.
        if (!this.state.isDataLoaded) return (<Preloader/>);

        const { classes } = this.props;
        const { assessment, lookups } = this.state;

        const buildComponentProps = () => {
            let componentProps = {
                assessment: assessment,
                lookups: lookups,

                handleGetAssessment: this.handleGetAssessment,

                // Methods for displaying notifications
                notification: {
                    success: this.displayNotification("success"),
                    info: this.displayNotification("info"),
                    warning: this.displayNotification("warning"),
                    error: this.displayNotification("error")
                },
            };
            return componentProps;
        };

        return (
            <React.Fragment>
                <NotificationSnackbar
                    open={this.state.notificationDisplayed}
                    handleClose={this.handleCloseNotification}
                    variant={this.state.notificationVariant}
                    message={this.state.notificationMessage}
                />

                <Grid item xs={12} sm={12} lg={8}>
                    <Grid item xs={12} sm={12}>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                sx= {{'&.MuiAccordionSummary-root':{
                                    display: 'flex',
                                    padding: 2
                                }}}
                                expandIcon={<ExpandMore/>}>
                                <div className={classes.column}>
                                    <Typography variant="h6" gutterBottom>
                                        Fatigue Assessment &nbsp;
                                        <RiskRatingScoreChip
                                            size="small"
                                            label={assessment.risk_rating}
                                            RiskRatingName={assessment.risk_rating}
                                            suffix=" Risk"
                                        />
                                    </Typography>
                                </div>

                                <div className={classes.column}>
                                    <Typography variant="h6">
                                        <AssessmentDetailsChip
                                            size="small"
                                            label={assessment.user_full_name}
                                            colour="blue"
                                        />
                                        &nbsp;
                                        <AssessmentDetailsChip
                                            size="small"
                                            label={assessment.shift_start_date}
                                            colour="blue"
                                            is_date={true}
                                        />
                                        &nbsp;
                                        <AssessmentDetailsChip
                                            size="small"
                                            label={assessment.shift_start_time}
                                            colour="blue"
                                        />
                                        &nbsp;
                                        <AssessmentDetailsChip
                                            size="small"
                                            label={assessment.depot}
                                            colour="blue"
                                        />
                                        &nbsp;
                                    </Typography>

                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" style={{width: '15%', whiteSpace: 'nowrap'}} className={classes.stickyHeader}>
                                                Question
                                            </TableCell>
                                            <TableCell align="center" style={{width: '15%', whiteSpace: 'nowrap'}} className={classes.stickyHeader}>
                                                Response
                                            </TableCell>
                                            <TableCell align="center" style={{width: '15%', whiteSpace: 'nowrap'}} className={classes.stickyHeader}>
                                                Response<br/> Risk Rating
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="right" style={{width: '60%'}}>
                                               Who Is Your Shift Supervisor?
                                            </TableCell>

                                            <TableCell align="center" style={{width: '20%'}}>
                                                {assessment.shift_supervisor_full_name}
                                            </TableCell>

                                            <TableCell align="center" style={{width: '20%'}}>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell align="right" style={{width: '60%'}}>
                                               How Many Hours Sleep Have You Had In The Last 24 Hours?
                                            </TableCell>

                                            <TableCell align="center" style={{width: '20%'}}>
                                                {assessment.hours_sleep_in_last_24Hours}
                                            </TableCell>

                                            <TableCell align="center" style={{width: '20%'}}>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell align="right" style={{width: '60%'}}>
                                               How Many Hours Sleep Have You Had In The Last 48 Hours?
                                            </TableCell>

                                            <TableCell align="center" style={{width: '20%'}}>
                                                {assessment.hours_sleep_in_last_48Hours}
                                            </TableCell>

                                            <TableCell align="center" style={{width: '20%'}}>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell align="right" style={{width: '60%'}}>
                                                How long will you have been awake by the end of your shift?
                                            </TableCell>

                                            <TableCell align="center" style={{width: '20%'}}>
                                                {assessment.hours_awake_till_end_of_shift}
                                            </TableCell>

                                            <TableCell align="center" style={{width: '20%'}}>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell align="right" style={{width: '60%'}}>
                                               Do You Feel Alert?
                                            </TableCell>

                                            <TableCell align="center" style={{width: '20%'}}>
                                                {assessment.do_you_feel_alert}
                                            </TableCell>

                                            <TableCell align="center" style={{width: '20%'}}>
                                                <IndividualFieldRiskRatingChip
                                                    size="small"
                                                    individualFieldRiskScore={assessment.get_do_you_feel_alert_score}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell align="right" style={{width: '60%'}}>
                                               How Many Alcoholic Drinks Did You Have Before Your Sleep?
                                            </TableCell>

                                            <TableCell align="center" style={{width: '20%'}}>
                                                {assessment.number_of_drinks_before_sleep}
                                            </TableCell>

                                            <TableCell align="center" style={{width: '20%'}}>
                                                <IndividualFieldRiskRatingChip
                                                    size="small"
                                                    individualFieldRiskScore={assessment.get_number_of_drinks_before_sleep_score}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell align="right" style={{width: '60%'}}>
                                               Are You On Any Medication Or Other Substances That Could Cause Drowsiness Or Cause You To Be Unfit For Work?
                                            </TableCell>

                                            <TableCell align="center" style={{width: '20%'}}>
                                                {assessment.any_medication_cause_unfit_for_work}
                                            </TableCell>

                                            <TableCell align="center" style={{width: '20%'}}>
                                                <IndividualFieldRiskRatingChip
                                                    size="small"
                                                    individualFieldRiskScore={assessment.get_any_medication_cause_unfit_for_work_score}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell align="right" style={{width: '60%'}}>
                                               Do You Have Any Stress, Health Problems Or Other Personal Problems That Are Significantly Affecting Your Concentration And/Or Sleep?
                                            </TableCell>

                                            <TableCell align="center" style={{width: '20%'}}>
                                                {assessment.other_problems_affecting_concentration}
                                            </TableCell>

                                            <TableCell align="center" style={{width: '20%'}}>
                                                <IndividualFieldRiskRatingChip
                                                    size="small"
                                                    individualFieldRiskScore={assessment.get_other_problems_affecting_concentration_score}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </AccordionDetails>
                        </Accordion>

                        {/* If Actions exist for this assessment show them, otherwise show the Action Taken form */}
                        { (assessment.actions.length > 0) ? (
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMore/>}
                                    sx= {{'&.MuiAccordionSummary-root':{
                                        display: 'flex',
                                        padding: 2
                                    }}}
                                >
                                    <div className={classes.column}>
                                        <Typography variant="h6">Action Taken</Typography>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {assessment.actions.map((action, index) => (
                                        // Note: The historical source Fatigue Assessment app allowed for multiple
                                        // 'Actions' to be taken however MAFAT only allows one. So while this is a 'map'
                                        // there should only ever be a single action available to show.
                                        <ActionTaken key={index} action={action} {...buildComponentProps()}/>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        ):(
                            <React.Fragment>
                                {/* Only show form if the risk is not rated Low */}
                                <React.Fragment>
                                    {/* Only show form if logged in user is specified as the shift supervisor */}
                                    {(loggedInUser.id === assessment.shift_supervisor_id) && (
                                        <Accordion defaultExpanded>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore/>}
                                                sx= {{'&.MuiAccordionSummary-root':{
                                                    display: 'flex',
                                                    padding: 2
                                                }}}
                                            >
                                                <div className={classes.column}>
                                                    <Typography variant="h6">Action Taken Form</Typography>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <FatigueAssessmentActionForm {...buildComponentProps()}/>
                                            </AccordionDetails>
                                        </Accordion>
                                    )}
                                </React.Fragment>
                            </React.Fragment>
                        )}

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                sx= {{'&.MuiAccordionSummary-root':{
                                    display: 'flex',
                                    padding: 2
                                }}}
                            >
                                <div className={classes.column}>
                                    <Typography variant="h6">User Data</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <UserData {...buildComponentProps()}/>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                sx= {{'&.MuiAccordionSummary-root':{
                                    display: 'flex',
                                    padding: 2
                                }}}
                            >
                                <div className={classes.column}>
                                    <Typography variant="h6">Recommended Actions</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RecommendedActions/>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}


AssessmentDetails.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(AssessmentDetails));

import React from 'react';
import {Route, Switch} from 'react-router-dom';

// Material UI
import {AddCircle} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';

// Containers
import FatigueAssessmentCreate from './containers/FatigueAssessmentCreate';
import FatigueAssessmentDetails from './containers/FatigueAssessmentDetails';
import UserProfileDetails from './containers/UserProfileDetails';

// Report Components
import ReportDirectReportsAssessments from './containers/ReportDirectReportsAssessments';
import ReportShiftSupervisorAssessments from './containers/ReportShiftSupervisorAssessments';
import ReportAssessmentsByHierarchy from './containers/ReportAssessmentsByHierarchy';
import ReportSummaryData from './containers/ReportSummaryData';

export const navigationRoutes = [
  {
    path: '/',
    name: 'New Assessment',
    icon: AddCircle,
    component: FatigueAssessmentCreate,
    selected: false,
    key: 'fa',
    keyId: uuidv4()
  }
];

function Routes() {
  return <Switch>
    {navigationRoutes.map((r) => <Route key={r.keyId} exact path={r.path} component={r.component} />)}

    <Route path="/assessment/:assessmentId" component={ FatigueAssessmentDetails } />
    <Route path="/userprofile/:userProfileId" component={ UserProfileDetails } />

    {/* Direct Reports */}
    <Route path="/reports/directreportassessments" component={ReportDirectReportsAssessments} />

    {/* Shift Supervisor Reports */}
    <Route path="/reports/shiftsupervisorassessments" component={ReportShiftSupervisorAssessments} />

    {/* Organisational Hierarchy Reports */}
    <Route path="/reports/assessmentsbyhierarchy" component={ReportAssessmentsByHierarchy} />

    {/* Summary Data Reports */}
    <Route path="/reports/summarydata" component={ReportSummaryData} />

  </Switch>
}

export default Routes;
import React from 'react';
import {withRouter} from 'react-router-dom';

// Material UI
import { withStyles } from '@mui/styles'
import {Grid, Typography, Card, CardContent, Tooltip, Paper} from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import {ChevronRight, ExpandMore, InfoOutlined} from '@mui/icons-material';

// Utils
import axios from "axios";
import * as moment from "moment";

// Components Shared
import Preloader from "../atoms/PreLoader/Preloader";
import RiskRatingCountChip from "../atoms/RiskRatingCountChip";
import HierarchyTreeItem from "./HierarchyTreeItem";

// API Services
import {getAssessmentsByHierarchy} from "../../api/UserService";

const styles = theme => ({
    root: {
        paddingRight: theme.spacing(2),
    },
    cardContent: {
        paddingBottom: '16px !important',
    },
    actionContent: {
        margin: '-2px',
    },
    icon: {
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    iconActive: {
        color: theme.palette.primary.main,
    },
    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 420,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
});

class WidgetChartAllReportsTree extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {

        let queryPayload = {
            params: {
                start_date: this.props.startDate,
                end_date: this.props.endDate,
            }
        };

        axios.all(
            [
                getAssessmentsByHierarchy(queryPayload),
            ])
            .then(axios.spread((assessmentsByHierarchy) => {
                    this.setState({
                        assessmentsByHierarchy: assessmentsByHierarchy.data,
                    });
                })
            )
    };

    render() {

        const { assessmentsByHierarchy } = this.state;
        const { classes, startDate, endDate } = this.props;

        // Show loading icon while waiting for results from backend
        if (!assessmentsByHierarchy) return (
            <Grid item xs={12}>
                <Paper>
                    <Preloader/>
                </Paper>
            </Grid>
        );

        const startDateAsText = moment(startDate).format("DD/MM/YYYY");
        const endDateAsText = moment(endDate).format("DD/MM/YYYY");

        return (
            <Grid item xs={12}>
                <Card>
                    <CardContent align="left">
                        <Typography variant="h6">
                            My Organisational Structure
                            <Tooltip
                                classes={{
                                    tooltip: classes.htmlTooltip,
                                }}
                                title={
                                    <React.Fragment>
                                        Shows organisational hierachy from you downwards, including a summary of assessments at each manager layer.
                                        <ul>
                                            <li><b>{'Criteria:'}</b>{' '}
                                                <ul>
                                                    <li><b>{'Shift Start Date = ' + startDateAsText + ' to ' + endDateAsText}</b>.{' '}</li>
                                                    <li><b>{'Employees Manager = You or a manager below you in the organisational hierarchy'}</b>{' '}</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </React.Fragment>
                                }
                            >
                                <InfoOutlined color='disabled'/>
                            </Tooltip>
                        </Typography>
                        <SimpleTreeView
                            className={classes.root}
                            defaultcollapseicon={<ExpandMore />}
                            defaultexpandicon={<ChevronRight />}
                            defaultexpanded={['1', '2', '3', '4', '5', '6']}
                        >

                            <TreeItem itemId="all-reports-item"  nodeid="1" label={assessmentsByHierarchy.email}>
                                <Typography variant="subtitle1" gutterBottom>
                                    {assessmentsByHierarchy.assessments.length} Assessments

                                    &nbsp;
                                    <RiskRatingCountChip
                                        size="small"
                                        label={assessmentsByHierarchy.assessments_count_low}
                                        RiskRatingName={'Low'}
                                    />
                                    &nbsp;
                                    <RiskRatingCountChip
                                        size="small"
                                        label={assessmentsByHierarchy.assessments_count_medium}
                                        RiskRatingName={'Medium'}
                                    />
                                    &nbsp;
                                    <RiskRatingCountChip
                                        size="small"
                                        label={assessmentsByHierarchy.assessments_count_high}
                                        RiskRatingName={'High'}
                                    />
                                </Typography>
                                <HierarchyTreeItem user={assessmentsByHierarchy}/>
                            </TreeItem>

                        </SimpleTreeView>
                    </CardContent>
                </Card>
            </Grid>
        );
    }
}
export default withRouter(withStyles(styles)(WidgetChartAllReportsTree));
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import AppContext from "../AppProvider";
import {Link as RouterLink} from 'react-router-dom';

// Material UI
import { withStyles } from '@mui/styles';
import { Box, Typography, Paper, TableBody, Link, TableRow, TableCell, Table } from '@mui/material';
import { Announcement, CheckCircle } from '@mui/icons-material';

// Components
import FatigueAssessmentCreateForm from '../components/organisms/FatigueAssessmentCreateForm';

// Components Shared
import Preloader from '../components/atoms/PreLoader/Preloader';
import NotificationSnackbar from '../components/molecules/NotificationSnackbar/NotificationSnackbar';

// API Services
import { searchUsers } from '../api/UserService';
import { getHours, getNumberOfDrinks, getGenders, getAlertness, getShiftStartDates, getShiftStartTimes } from '../api/LookupsService';

// Styling overrides - used to override and set the styling/css of the page
const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.up('md')]: {
            width: "900px",
            margin: "auto"
        }
    }
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{padding: 8 * 3}}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class FatigueAssessmentCreate extends React.Component {
    static contextType = AppContext;

    state = {
        // Lookups data has not yet been loaded
        isLookupsLoaded: false,
        assessment: null,
    };

    // Notification Snackbars
    displayNotification = variant => message => {
        this.setState({
            notificationDisplayed: true,
            notificationMessage: message,
            notificationVariant: variant
        });
    };

    handleCloseNotification = () => {
        this.setState({
            notificationDisplayed: false
        });
    };

    // ===================================================================================================
    // User Search COMMON
    // ===================================================================================================
    // ---- Set state of search values ----------------------------------------------
    handleUpdateUserSearchText = (inputValue) => {

        let searchVal = inputValue.replace(/'/g, "''");

        this.setState({
            userSearchText: searchVal,
        });
    };

    // ---- Users Search -------------------------------------------------------------
    handleSearchUsers = async () => {
        let mappedResults = [];
        let payload = {
            params: {
                q: this.state.userSearchText,  // Search string
            }
        };

        // Query the asset search API endpoint
        let searchResults = await searchUsers(payload);

        // Map the results into the format required by the select box
        searchResults.data.results.map(x => mappedResults.push({
            'identifier': x.id,
            'displayName': x.email,
        }));

        return mappedResults;
    };

    componentDidMount() {

        // Get lookups data
        axios.all([
            getHours(),
            getNumberOfDrinks(),
            getGenders(),
            getAlertness(),
            getShiftStartDates(),
            getShiftStartTimes(),
        ])
            .then(axios.spread((
                hours,
                numberOfDrinks,
                genders,
                alertness,
                shiftstartdates,
                shiftstarttimes,
            ) => {

                this.setState({
                    // Lookups
                    lookups: {
                        hours: [...hours.data],
                        numberOfDrinks: [...numberOfDrinks.data],
                        genders: [...genders.data],
                        alertness: [...alertness.data],
                        shiftstartdates: [...shiftstartdates.data],
                        shiftstarttimes: [...shiftstarttimes.data],
                    },

                    // Change state to show that lookups have been loaded
                    isLookupsLoaded: true
                });
            }));
    }

    onSubmitSuccess = (response) => {
        this.setState({
            assessment: response.data
        });
    };

    render() {
        // Display loading icon until all required data has been gathered.
        if (!this.state.isLookupsLoaded) return (<Preloader/>);

        const { classes } = this.props;
        const { lookups, assessment} = this.state;

        const buildComponentProps = modelProp => {
            let componentProps = {

                user: this.context.user,

                // Group all "lookup" elements together
                lookups: lookups,
                // Methods for displaying notifications
                notification: {
                    success: this.displayNotification("success"),
                    info: this.displayNotification("info"),
                    warning: this.displayNotification("warning"),
                    error: this.displayNotification("error")
                },
                // Group navigation functions
                navigation: {
                    onSubmitSuccess: this.onSubmitSuccess
                },

                handleUpdateUserSearchText: this.handleUpdateUserSearchText,
                handleSearchUsers: this.handleSearchUsers,
            };
            return componentProps;
        };

        return (

            <Paper className={classes.root} elevation={1}>

                {assessment ? (
                    <React.Fragment>
                        {assessment.risk_rating === "Low" ? (
                            <React.Fragment>
                                <Typography variant="h5" gutterBottom>
                                    <CheckCircle/>
                                    Thankyou {this.props.user}
                                </Typography>

                                <Typography variant="subtitle1" gutterBottom>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ 'backgroundColor': '#e0efd9' }}>
                                                    Please report to your leadership team for your task allocation.
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Typography>

                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Typography variant="h5" gutterBottom>
                                    <Announcement/>
                                    Thankyou {this.props.user}
                                </Typography>

                                <Typography variant="subtitle1" gutterBottom>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ 'backgroundColor': '#f1dede' }}>
                                                    Please report to your leadership team immediately.<br/>
                                                    Do not commence work until further advice from your leadership team.
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Typography>

                            </React.Fragment>
                        )
                        }
                        <Box align="right">
                            <Link
                                component={RouterLink}
                                to={"/assessment/" + assessment.id}
                            >
                                View Submission Details
                            </Link>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Typography variant="h5" gutterBottom>Fatigue Assessment Form{this.props.user}</Typography>
                        <FatigueAssessmentCreateForm {...buildComponentProps('assetDetails')}/>
                    </React.Fragment>
                )
                }

                <NotificationSnackbar
                    open={this.state.notificationDisplayed}
                    handleClose={this.handleCloseNotification}
                    variant={this.state.notificationVariant}
                    message={this.state.notificationMessage}
                />
            </Paper>
        );
    }
}

FatigueAssessmentCreate.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(FatigueAssessmentCreate));

import React, { useContext } from "react";
import withStyles from '@mui/styles/withStyles';
import Colours from "./Colours";
import LayoutContext from "../LayoutContext";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "150px",
    marginTop: "6px",
    justifyContent: "space-between",
  },
  square: {
    width: "16px",
    height: "16px",
    cursor: "pointer",
  },
  orange: {
    backgroundColor: Colours.orange.primary["500"],
    "&:hover": {
      outline: `3px solid ${Colours.orange.secondary["300"]}`,
    },
    "&.selected": {
      outline: `3px solid ${Colours.orange.secondary["300"]}`,
    },
  },
  blue: {
    backgroundColor: Colours.blue.primary["500"],
    "&:hover": {
      outline: `3px solid ${Colours.blue.secondary["300"]}`,
    },
    "&.selected": {
      outline: `3px solid ${Colours.blue.secondary["300"]}`,
    },
  },
  green: {
    backgroundColor: Colours.green.primary["500"],
    "&:hover": {
      outline: `3px solid ${Colours.green.secondary["300"]}`,
    },
    "&.selected": {
      outline: `3px solid ${Colours.green.secondary["300"]}`,
    },
  },
  purple: {
    backgroundColor: Colours.purple.primary["500"],
    "&:hover": {
      outline: `3px solid ${Colours.purple.secondary["300"]}`,
    },
    "&.selected": {
      outline: `3px solid ${Colours.purple.secondary["300"]}`,
    },
  },
  turquoise: {
    backgroundColor: Colours.turquoise.primary["500"],
    "&:hover": {
      outline: `3px solid ${Colours.turquoise.secondary["300"]}`,
    },
    "&.selected": {
      outline: `3px solid ${Colours.turquoise.secondary["300"]}`,
    },
  },
});

function ThemeColours(props) {
  const { classes } = props;

  const layoutContext = useContext(LayoutContext);

  const handleClick = (event) => {
    layoutContext.updateTheme("colour", event.currentTarget.title);
  };

  const isSelected = (colour) => {
    return colour === layoutContext.colour ? "selected" : "";
  };

  return (
    <div className={classes.root}>
      <div
        className={`${classes.square} ${classes.orange} ${isSelected(
          "orange"
        )}`}
        onClick={handleClick}
        title="orange"
      ></div>
      <div
        className={`${classes.square} ${classes.blue} ${isSelected("blue")}`}
        onClick={handleClick}
        title="blue"
      ></div>
      <div
        className={`${classes.square} ${classes.green} ${isSelected("green")}`}
        onClick={handleClick}
        title="green"
      ></div>
      <div
        className={`${classes.square} ${classes.purple} ${isSelected(
          "purple"
        )}`}
        onClick={handleClick}
        title="purple"
      ></div>
      <div
        className={`${classes.square} ${classes.turquoise} ${isSelected(
          "turquoise"
        )}`}
        onClick={handleClick}
        title="turquoise"
      ></div>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(ThemeColours);

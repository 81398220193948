import React from 'react';

import {
    Bar,
    Line,
    ComposedChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip as ReTooltip,
    XAxis,
    YAxis
} from "recharts";

// Wraps the formik ErrorMessage so it renders a FormHelperText compoent for rendering errors
const LineBarComposedChartAssessmentRiskRatings = props => {
    const {data} = props;

    return (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 20, right: 30, left: 20, bottom: 10,
                }}
            >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="name" tick={{fontSize: 14}}/>
                <YAxis tick={{fontSize: 14}} allowDecimals={false}/>
                <ReTooltip />
                <Legend />

                <Bar dataKey="Low" stackId="a" fill="#5cb85c" />
                <Bar dataKey="Medium" stackId="a" fill="#f0ad4e" />
                <Bar dataKey="High" stackId="a" fill="#d9534f" />
                <Line type="monotone" dataKey="Actions" stroke="#ff00f4" dot={false} />
            </ComposedChart>
        </ResponsiveContainer>
    )
};

export default LineBarComposedChartAssessmentRiskRatings;


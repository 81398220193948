import * as msal from '@azure/msal-browser'; // ESM
import msalSettings from './MsalSettings';

class MsalService {
  constructor(config) {
    const existingHash = window.location.hash;
    let pathDefault = window.location.href;

    if (existingHash) {
      pathDefault = pathDefault.replace(existingHash, '');
    }

    const msalConfig = {
      auth: {
        clientId: config.clientId,
        authority: `https://login.microsoftonline.com/${config.tenant}`,
        redirectUri: config.redirectUri || pathDefault
      },
      cache: {
        cacheLocation: 'localStorage'
      },
      system: {
        loggerOptions: {
          loggerCallback(loglevel, message, containsPii) {
            /* eslint-disable no-console */
            console.log(message);
          },
          piiLoggingEnabled: false,
          logLevel: msal.LogLevel.Warning
        }
      }
    };

    this.user = null;
    // ------ Create instance with given config ------
    this.authContext = new msal.PublicClientApplication(msalConfig);
  }

  runWithAdal(app, doNotLogin = true) {
    const account = this.authContext.getActiveAccount();
    if (account) {
      this.user = account;
      app();
      return;
    }

    this.authContext
      .handleRedirectPromise()
      .then((response) => {
        if (response) {
          this.authContext.setActiveAccount(response.account);
          this.user = response.account;
          app();
        } else {
          this.login();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  login() {
    this.authContext.loginRedirect();
  }

  logout() {
    this.authContext = null;
    this.user = null;
  }

  async acquireToken(requestUrl) {
    const self = this;
    const account = self.user || self.authContext.getActiveAccount();
    if (!account) {
      return '';
    }

    const response = await self.authContext.acquireTokenSilent({ account, scopes: ['User.Read'] });
    return response.accessToken;
  }
}

export default new MsalService(msalSettings);

import React from 'react';
import {withRouter} from 'react-router-dom';

// Material UI
import { withStyles } from '@mui/styles'
import Chip from '@mui/material/Chip';

// Styling overrides - used to override and set the styling/css of the page
const styles = theme => ({});

class TerminatedUserChip extends React.Component {

    render() {
        const { label, size } = this.props;

        return (
            <Chip
                size={size}
                label={"No longer at BHP. Exited on " + label}
                sx={{
                    '&.MuiChip-root': {
                        fontWeight: 700,
                        fontSize: '11px',
                        borderRadius: '5px',
                        fontFamily: "Arial, Helvetica, sans-serif",
                        borderColor: '#dc4649',
                    },
                    '& .MuiChip-label': {
                        color: '#2d3032',
                    }
                }}
            />
        );
    }
}

export default withRouter(withStyles(styles)(TerminatedUserChip));
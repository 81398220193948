import React from 'react';
import { withRouter } from 'react-router-dom';

// Material UI
import { withStyles } from '@mui/styles';
import { Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import RiskRatingScoreChip from "../atoms/RiskRatingScoreChip";
import Typography from "@mui/material/Typography";

// Styling overrides - used to override and set the styling/css of the page
const styles = theme => ({
});

class RecommendedActions extends React.Component {

    render() {

        const { classes } = this.props;

        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={{width: '15%', whiteSpace: 'nowrap'}} className={classes.stickyHeader}>
                            Level of Risk
                        </TableCell>
                        <TableCell style={{width: '15%', whiteSpace: 'nowrap'}} className={classes.stickyHeader}>
                            Recommended Action
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={{ 'backgroundColor': '#e0efd9' }}>
                            <Typography variant="h6" gutterBottom>
                                <RiskRatingScoreChip
                                    size="small"
                                    label="Low"
                                    RiskRatingName="Low"
                                    suffix=" Risk"
                                />
                            </Typography>
                            <strong>ALL LOW RISK</strong> AND individual is
                            <ul>
                                <li>Alert</li>
                                <li>Normal eye blinks (less than 1 second)</li>
                                <li>Coordinated body movements</li>
                                <li>Tolerant of others</li>
                            </ul>
                        </TableCell>

                        <TableCell style={{ 'backgroundColor': '#e0efd9' }}>
                            <ul>
                                <li>Continue to monitor</li>
                                <li>Remind individuals about fatigue and alertness management strategies (interaction with others, coffee, exercise, cold air on face, etc)</li>
                            </ul>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{ 'backgroundColor': '#fcf7e4' }}>
                            <Typography variant="h6" gutterBottom>
                                <RiskRatingScoreChip
                                    size="small"
                                    label="Medium"
                                    RiskRatingName="Medium"
                                    suffix=" Risk"
                                />
                            </Typography>
                            <strong>ANY MEDIUM RISK SCORE</strong> OR The individual reports they are fatigued and/or are showing some of the following signs:
                            <ul>
                                <li>Irritable/impatient</li>
                                <li>Longer eyelid closure (1-2 seconds)</li>
                                <li>Wandering thoughts</li>
                                <li>Rubs eyes or face</li>
                                <li>Facial contortions</li>
                                <li>Restless movements</li>
                                <li>Yawning</li>
                            </ul>
                        </TableCell>

                        <TableCell style={{ 'backgroundColor': '#fcf7e4' }}>
                            As above plus …
                            <ul>
                                <li>Work with self-monitored risk reductions measures</li>
                                <li>Discuss possible reasons for fatigue</li>
                                <li>Rotate tasks</li>
                                <li>Encourage the use of alertness strategies (strategic use of caffeine, self check routines)</li>
                                <li>Provide opportunity for a short breaks/brief nap of no more than 15 minutes if possible</li>
                                <li>Have personnel work together (if possible)</li>
                                <li>Consider removal from safety sensitive work</li>
                                <li>Schedule regular supervision for remainder of shift</li>
                            </ul>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{ 'backgroundColor': '#f1dede' }}>
                            <Typography variant="h6" gutterBottom>
                                <RiskRatingScoreChip
                                    size="small"
                                    label="High"
                                    RiskRatingName="High"
                                    suffix=" Risk"
                                />
                            </Typography>
                            <strong>ANY HIGH RISK RESPONSE</strong> OR The individual reports they are significantly fatigued and/or may be showing the following serious signs:
                            <ul>
                                <li>Quiet and withdrawn</li>
                                <li>Long eyelid closure (2 or more seconds)</li>
                                <li>Fixed staring</li>
                                <li>Frequent yawning</li>
                                <li>Micro sleeps</li>
                            </ul>
                        </TableCell>

                        <TableCell style={{ 'backgroundColor': '#f1dede' }}>
                            <ul>
                                <li>Immediately prevent person from completing safety critical work and discuss the possible causes and action required</li>
                                <li>Determine if the individual can be placed on alternate duties for the remainder of shift and managed at work</li>
                                <li>If unable to be managed on alternate duties, send the individual home (provide transportation) and report the event in EMS</li>
                            </ul>
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        );
    }
}

export default withRouter(withStyles(styles)(RecommendedActions));
import React from 'react';
import {withRouter} from 'react-router-dom';

// Material UI
import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

// Components
import RiskRatingCountChip from "../atoms/RiskRatingCountChip";

// Styling overrides - used to override and set the styling/css of the page
const styles = theme => ({
});

class HierarchyTreeItem extends React.Component {

    render() {

        const { user } = this.props;

        return (
            <React.Fragment>
                {user.directreports.map((user, index) => (
                    <React.Fragment key={index}>
                        {(user.is_manager) &&
                            <TreeItem itemId="is-manager-hierarchy-item" nodeid={user.email} label={user.email}>
                                <Typography variant="subtitle1" gutterBottom>
                                    {user.assessments.length} Assessments

                                    &nbsp;
                                    <RiskRatingCountChip
                                        size="small"
                                        label={user.assessments_count_low}
                                        RiskRatingName={'Low'}
                                    />
                                    &nbsp;
                                    <RiskRatingCountChip
                                        size="small"
                                        label={user.assessments_count_medium}
                                        RiskRatingName={'Medium'}
                                    />
                                    &nbsp;
                                    <RiskRatingCountChip
                                        size="small"
                                        label={user.assessments_count_high}
                                        RiskRatingName={'High'}
                                    />
                                </Typography>
                                <HierarchyTreeItem user={user}/>
                            </TreeItem>
                        }
                    </React.Fragment>
                ))}
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(styles)(HierarchyTreeItem));
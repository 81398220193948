import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Hidden, Box } from '@mui/material';
import * as Sentry from '@sentry/react';

import withStyles from '@mui/styles/withStyles';
import MenuIcon from '@mui/icons-material/Menu';
import SideBar from './SideBar';
import classNames from 'classnames';
import SettingPanel from './SettingPanel';
import { LayoutProvider } from './LayoutContext';
import useMediaQuery from '@mui/material/useMediaQuery';

const drawerWidth = '250px';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  appBarShift: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100vw - ${drawerWidth})`
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  appToolBar: {
    paddingLeft: theme.spacing(3)
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  title: {
    flex: 1
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#252525',
    color: 'white',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      position: 'relative'
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    },
    '& .menu-items': {
      '& .menu-item': {
        padding: '10px',
        marginRight: '15px',
        '& .menu-item-text': {
          visibility: 'hidden'
        }
      },
      '& .selected .menu-item': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '& .menu-icon': {
          color: theme.palette.primary.main
        }
      }
    }
  },
  logo: {
    flexGrow: 1,
    lineHeight: 0,
    marginLeft: '24px',
    color: 'white'
  },
  toolbarLabel: {
    color: '#fff'
  },
  contentWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default
  },
  content: {
    overflow: 'auto',
    padding: theme.spacing(3)
  },
  topBarActions: {
    marginRight: '12px'
  },
  flexDiv: {
    display: 'flex',
    height: '100%'
  }
});

function MainLayout(props) {
  const TopBarActions = props.topBarActions;
  const Logo = props.logo;
  const { classes, theme } = props;

  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [mobileSideBarOpen, setMobileSideBarOpen] = useState(false);
  const [settingPanelOpen, setSettingPanelOpen] = useState(false);

  const handleSettingPanelToggle = (event) => {
    setSettingPanelOpen(!settingPanelOpen);
  };

  const handleDrawerToggle = (event) => {
    setMobileSideBarOpen(!mobileSideBarOpen);
    setSideBarOpen(!sideBarOpen);
  };

  const mobileSidebarHidden = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <LayoutProvider
      value={{
        updateTheme: props.updateTheme,
        colour: props.colour
      }}
    >
      <div className={classes.root}>
        <AppBar className={classNames(classes.appBar, sideBarOpen && classes.appBarShift)}>
          <Toolbar disableGutters
            sx={{
              ...(sideBarOpen && {
                pl: theme.spacing(3)
              })
            }}
          >
            <IconButton
              sx={{
                  ml: "12px",
                  mr: "36px",
                  ...(sideBarOpen && {
                    '&.MuiButtonBase-root': {
                      display: {md: 'none'}
                  }}),
              }}
              color="inherit"
              onClick={handleDrawerToggle}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <Typography className={classes.title} variant="h5" color="inherit">
              {props.title}
            </Typography>
            <div className={classes.topBarActions}>
              <TopBarActions toggleSettings={handleSettingPanelToggle} />
            </div>
          </Toolbar>
        </AppBar>
        {!mobileSidebarHidden && 
          (<SideBar
            theme={theme}
            classes={classes}
            drawerClasses={{
              paper: classes.drawerPaper
            }}
            open={mobileSideBarOpen}
            onClose={handleDrawerToggle}
            logo={<Logo />}
            menuItems={props.menuItems}
          />)
        }
        <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
          <SideBar
            variant="permanent"
            open={sideBarOpen}
            onClose={handleDrawerToggle}
            theme={theme}
            classes={classes}
            drawerClasses={{
              root: classes.flexDiv,
              paper: classNames(classes.drawerPaper, !sideBarOpen && classes.drawerPaperClose)
            }}
            logo={<Logo />}
            menuItems={props.menuItems}
          />
        </Box>

        <Box className={classes.contentWrapper} sx={{ width: `calc(100vw - ${sideBarOpen ? '255' : '74'}px)` }}>
          <div className={classes.toolbar} />
          <Sentry.ErrorBoundary
            fallback={
              <Box sx={{ p: 2 }}>
                <h3>An unexpected error has occured</h3>
                <p>
                  Try refreshing the page or navigate to another page. We have been notified automatically that this
                  happened but please feel free to reach out to us with more details.
                </p>
              </Box>
            }
          >
            <div className={classes.content}>{props.children}</div>
          </Sentry.ErrorBoundary>
        </Box>

        <SettingPanel variant="temporary" open={settingPanelOpen} onClose={handleSettingPanelToggle}></SettingPanel>
      </div>
    </LayoutProvider>
  );
}

export default withStyles(styles, { withTheme: true })(MainLayout);

import React, {Component} from 'react';
import {forwardRef} from 'react';

// Context
import AppContext from '../AppProvider';

// Material UI
import { withStyles } from '@mui/styles';
import { Button, Grid, Paper, Tooltip, Typography } from "@mui/material";
import {InfoOutlined} from "@mui/icons-material";
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';

// Utils
import MaterialTable from '@material-table/core';
import axios from 'axios';
import * as moment from "moment";

// Components Shared
import Preloader from '../components/atoms/PreLoader/Preloader';
import DateRangePickerDialog from "../components/organisms/DateRangePickerDialog";
import WidgetLineBarComposedChartAssessmentsByRiskRatingForDateRange from "../components/organisms/WidgetLineBarComposedChartAssessmentsByRiskRatingForDateRange";

// API Services
import {
    getAssessmentsCountByRiskRatingForDateRange,
    getAssessmentsCountByShiftSupervisorForDateRange,
    getAssessmentsCountByEmployeeForDateRange,
    getActionsCountByShiftSupervisorForDateRange,
    getAssessmentsRequiringActionCountForDateRange,
    getAssessmentsCountForDateRange,
    getActionsCountForDateRange,
    getUsersWhoHaveLoggedInCountForDateRange
} from "../api/WidgetService";
import dayjs from 'dayjs';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 420,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
});

class ReportSummaryData extends Component {
    static contextType = AppContext;

    state = {
        isLoading: true,

        isDateRangePickerOpen: false,

        startDate: moment(new Date()).subtract(30,'days').format("YYYY-MM-DD"),
        endDate: moment(new Date()).add(0,'days').format("YYYY-MM-DD"),
    };

    handleSetStartDate = (value) => {
        this.setState(() => ({
            startDate: moment(value).format("YYYY-MM-DD"),
        }))
    };

    handleSetEndDate = (value) => {
        this.setState(() => ({
            endDate: moment(value).format("YYYY-MM-DD"),
        }))
    };

    handleClickDateRangePickerOpen = () => {
        this.setState(() => ({
            isDateRangePickerOpen: true,
        }))
    };

    handleClickDateRangePickerFilter = () => {
        this.setState(() => ({
            isDateRangePickerOpen: false,
        }));
        this.handleGetAssessments();
    };

    handleClickDateRangePickerClose = () => {
        this.setState(() => ({
            isDateRangePickerOpen: false,
        }));
    };

    // ===================================================================================================
    // Widgets data
    // ===================================================================================================
    handleGetAssessments = () => {

        this.setState({
            // Clear the data, causing the 'loading icon' to show
            assessmentsCountByRiskRatingForDateRange: null,
            assessmentsCountByShiftSupervisorForDateRange: null,
            assessmentsCountByEmployeeForDateRange: null,
            actionsCountByShiftSupervisorForDateRange: null,
        });

        let queryPayload = {
            params: {
                // shiftsupervisor_user_id: this.context.user.id,
                start_date: this.state.startDate,
                end_date: this.state.endDate,
            }
        };

        axios.all(
            [
                getAssessmentsCountByRiskRatingForDateRange(queryPayload),
                getAssessmentsCountByShiftSupervisorForDateRange(queryPayload),
                getAssessmentsCountByEmployeeForDateRange(queryPayload),
                getActionsCountByShiftSupervisorForDateRange(queryPayload),
                getAssessmentsRequiringActionCountForDateRange(queryPayload),
                getAssessmentsCountForDateRange(queryPayload),
                getActionsCountForDateRange(queryPayload),
                getUsersWhoHaveLoggedInCountForDateRange(queryPayload),
            ])
            .then(axios.spread((assessmentsCountByRiskRatingForDateRange,
                                assessmentsCountByShiftSupervisorForDateRange,
                                assessmentsCountByEmployeeForDateRange,
                                actionsCountByShiftSupervisorForDateRange,
                                assessmentsRequiringActionsCountForDateRange,
                                assessmentsCountForDateRange,
                                actionsCountForDateRange,
                                usersWhoHaveLoggedInCountForDateRange) => {
                this.setState({
                    assessmentsCountByRiskRatingForDateRange: assessmentsCountByRiskRatingForDateRange.data,
                    assessmentsCountByShiftSupervisorForDateRange: assessmentsCountByShiftSupervisorForDateRange.data,
                    assessmentsCountByEmployeeForDateRange: assessmentsCountByEmployeeForDateRange.data,
                    actionsCountByShiftSupervisorForDateRange: actionsCountByShiftSupervisorForDateRange.data,
                    assessmentsRequiringActionsCountForDateRange: assessmentsRequiringActionsCountForDateRange.data,
                    assessmentsCountForDateRange: assessmentsCountForDateRange.data,
                    actionsCountForDateRange: actionsCountForDateRange.data,
                    usersWhoHaveLoggedInCountForDateRange: usersWhoHaveLoggedInCountForDateRange.data,
                    isLoading: false,
                });
            }))
    };

    componentDidMount() {
        this.handleGetAssessments();
    };

    render() {
        // Show loading icon while waiting for results from backend
        if (this.state.isLoading) return (
            <Grid item xs={12}>
                <Paper>
                    <Preloader/>
                </Paper>
            </Grid>
        );

        const {
            startDate,
            endDate,
            isDateRangePickerOpen,
            assessmentsCountByRiskRatingForDateRange,
            assessmentsCountByShiftSupervisorForDateRange,
            assessmentsCountByEmployeeForDateRange,
            actionsCountByShiftSupervisorForDateRange,
            assessmentsRequiringActionsCountForDateRange,
            assessmentsCountForDateRange,
            actionsCountForDateRange,
            usersWhoHaveLoggedInCountForDateRange,
            } = this.state;
        const { classes } = this.props;

        const startDateAsText = moment(startDate).format("DD/MM/YYYY");
        const endDateAsText = moment(endDate).format("DD/MM/YYYY");

        return (
            <React.Fragment>
                {(isDateRangePickerOpen) &&
                    <DateRangePickerDialog
                        startDate={dayjs(startDate)}
                        endDate={dayjs(endDate)}
                        onStartDateChange={this.handleSetStartDate}
                        onEndDateChange={this.handleSetEndDate}
                        onFilterClick={this.handleClickDateRangePickerFilter}
                        onFilterCloseClick={this.handleClickDateRangePickerClose}
                    />
                }
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} align={"left"}>
                        <Typography variant="caption" display={"inline"}>
                            Date Range:&nbsp;
                        </Typography>
                        <Typography variant="subtitle1" display={"inline"}>
                            &nbsp;
                            {moment(startDate).format("ll")} -> {moment(endDate).format("ll")}
                            &nbsp;
                            <Button variant="outlined" color="secondary" onClick={this.handleClickDateRangePickerOpen}>
                                <Edit/>
                            </Button>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <WidgetLineBarComposedChartAssessmentsByRiskRatingForDateRange
                            data={assessmentsCountByRiskRatingForDateRange}
                            tableTitleTooltipText={
                                <React.Fragment>
                                    <b>
                                       {'Shows a count of all Assessments by Risk Rating by day (Stacked Bar) & count of Actions by day (Line Chart). '}
                                    </b>
                                    <ul>
                                        <li><b>{'Assessments Criteria:'}</b>{' '}
                                            <ul>
                                                <li><b>{'Shift Date (Range) = ' + startDateAsText + ' to ' + endDateAsText}</b>{' '}</li>
                                                <li><b>{'Risk Rating = Low, Medium, High'}</b>{' '}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </React.Fragment>
                            }
                        />
                    </Grid>


                    <Grid item xs={12} sm={3} align={"left"}>
                        <Paper>
                            # of Assessments:
                            {assessmentsCountForDateRange}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={3} align={"left"}>
                        <Paper>
                            # of Actions:
                            {actionsCountForDateRange}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={3} align={"left"}>
                        <Paper>
                            # of Unactioned Assessments:
                            {assessmentsRequiringActionsCountForDateRange}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={3} align={"left"}>
                        <Paper>
                            # of Users who have logged in:
                            {usersWhoHaveLoggedInCountForDateRange}
                        </Paper>
                    </Grid>


                    <Grid item xs={12} sm={4}>
                        {/* Show loading icon while waiting for results from backend */}
                        {(!assessmentsCountByShiftSupervisorForDateRange) ? (
                            <Paper>
                                <Preloader/>
                            </Paper>
                        ) : (
                            <MaterialTable
                                columns={[
                                    {
                                        title: 'Shift Supervisor (email)',
                                        field: 'shift_supervisor__email',
                                    },
                                    {
                                        title: 'Assessments Count',
                                        field: 'count',
                                    }
                                ]}
                                data={assessmentsCountByShiftSupervisorForDateRange}
                                title={
                                    <React.Fragment>
                                        <Typography>
                                            Assessments count by Shift Supervisor
                                            <Tooltip
                                                classes={{
                                                    tooltip: classes.htmlTooltip,
                                                }}
                                                title={
                                                    <React.Fragment>
                                                        Shows a count Assessments by the assigned Shift Supervisor
                                                        <ul>
                                                            <li><b>{'Assessments Criteria:'}</b>{' '}
                                                                <ul>
                                                                    <li><b>{'Shift Start Date = ' + startDateAsText + ' to ' + endDateAsText}</b>.{' '}</li>
                                                                    <li><b>{'Risk Rating = Low, Medium, High'}</b>{' '}</li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </React.Fragment>
                                                }
                                            >
                                                <InfoOutlined color='disabled'/>
                                            </Tooltip>
                                        </Typography>
                                    </React.Fragment>
                                }
                                icons={tableIcons}
                                options={{
                                    exportButton: true,
                                    exportAllData: true,
                                    exportFileName: 'Assessments_Count_By_Supervisor',
                                    grouping: false,
                                    defaultExpanded: true,
                                    pageSize: 50,
                                    pageSizeOptions: [20, 50, 100, 200, 1000],
                                    emptyRowsWhenPaging: false,
                                    idSynonym: 'shift_supervisor__email' 
                                    // It seems material table will not generate this id for you. It expects a "id" property in the row data and uses that as the unique id. If you have a separate property that provides uniqueness you can use the "idSynonym" option to map to it otherwise you need to generate the 'id' property yourself.
                                }}
                            />
                        )}
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        {/* Show loading icon while waiting for results from backend */}
                        {(!actionsCountByShiftSupervisorForDateRange) ? (
                            <Paper>
                                <Preloader/>
                            </Paper>
                        ) : (
                            <MaterialTable
                                columns={[
                                    {
                                        title: 'Shift Supervisor',
                                        field: 'controls_implemented_by__email',
                                    },
                                    {
                                        title: 'Actions Count',
                                        field: 'count',
                                    }
                                ]}
                                data={actionsCountByShiftSupervisorForDateRange}
                                title={
                                    <React.Fragment>
                                        <Typography>
                                            Actions count by Shift Supervisor
                                            <Tooltip
                                                classes={{
                                                    tooltip: classes.htmlTooltip,
                                                }}
                                                title={
                                                    <React.Fragment>
                                                        Shows a count Actions by Shift Supervisor
                                                        <ul>
                                                            <li><b>{'Actions Criteria:'}</b>{' '}
                                                                <ul>
                                                                    <li><b>{'Timestamp Date = ' + startDateAsText + ' to ' + endDateAsText}</b>.{' '}</li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </React.Fragment>
                                                }
                                            >
                                                <InfoOutlined color='disabled'/>
                                            </Tooltip>
                                        </Typography>
                                    </React.Fragment>
                                }
                                icons={tableIcons}
                                options={{
                                    exportButton: true,
                                    exportAllData: true,
                                    exportFileName: 'Actions_Count_By_Supervisor',
                                    grouping: false,
                                    defaultExpanded: true,
                                    pageSize: 50,
                                    pageSizeOptions: [20, 50, 100, 200, 1000],
                                    emptyRowsWhenPaging: false,
                                }}
                            />
                        )}
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        {/* Show loading icon while waiting for results from backend */}
                        {(!assessmentsCountByEmployeeForDateRange) ? (
                            <Paper>
                                <Preloader/>
                            </Paper>
                        ) : (
                            <MaterialTable
                                columns={[
                                    {
                                        title: 'Employee',
                                        field: 'user__email',
                                    },
                                    {
                                        title: 'Assessments Count',
                                        field: 'count',
                                    }
                                ]}
                                data={assessmentsCountByEmployeeForDateRange}
                                title={
                                    <React.Fragment>
                                        <Typography>
                                            Assessments count by Employee
                                            <Tooltip
                                                classes={{
                                                    tooltip: classes.htmlTooltip,
                                                }}
                                                title={
                                                    <React.Fragment>
                                                        Shows a count Assessments by Employee
                                                        <ul>
                                                            <li><b>{'Assessments Criteria:'}</b>{' '}
                                                                <ul>
                                                                    <li><b>{'Shift Start Date = ' + startDateAsText + ' to ' + endDateAsText}</b>.{' '}</li>
                                                                    <li><b>{'Risk Rating = Low, Medium, High'}</b>{' '}</li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </React.Fragment>
                                                }
                                            >
                                                <InfoOutlined color='disabled'/>
                                            </Tooltip>
                                        </Typography>
                                    </React.Fragment>
                                }
                                icons={tableIcons}
                                options={{
                                    exportButton: true,
                                    exportAllData: true,
                                    exportFileName: 'Assessments_Count_By_Employee',
                                    grouping: false,
                                    defaultExpanded: true,
                                    pageSize: 50,
                                    pageSizeOptions: [20, 50, 100, 200, 1000],
                                    emptyRowsWhenPaging: false,
                                }}
                            />
                        )}
                    </Grid>

                </Grid>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(ReportSummaryData);
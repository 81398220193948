import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import AppContext from "../../AppProvider";

// Material UI
import { withStyles } from '@mui/styles'
import {Grid, Typography, Card, CardContent, Tooltip, Paper} from '@mui/material';
import {InfoOutlined} from '@mui/icons-material';

// Utils
import axios from "axios";
import * as moment from "moment";

// Components Shared
import Preloader from "../atoms/PreLoader/Preloader";
import BarChartAssessmentRiskRatings from "../molecules/BarChartAssessmentRiskRatings/BarChartAssessmentRiskRatings";

// API Services
import { getAllReportsAssessmentsCountByRiskRatingForDateRange } from "../../api/WidgetService";

const styles = theme => ({
    root: {
        paddingRight: theme.spacing(2),
    },
    cardContent: {
        paddingBottom: '16px !important',
    },
    actionContent: {
        margin: '-2px',
    },
    icon: {
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    iconActive: {
        color: theme.palette.primary.main,
    },
    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
});

class WidgetChartMyAllReportsAssessmentsByRiskRatingForDateRange extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        }
    }

    componentDidMount() {

        let queryPayload = {
            params: {
                start_date: this.props.startDate,
                end_date: this.props.endDate,
            }
        };
        axios.all(
            [
                getAllReportsAssessmentsCountByRiskRatingForDateRange(queryPayload),
            ])
            .then(axios.spread((assessmentsCount) => {

                this.setState({
                    assessmentsCount: assessmentsCount.data,
                    isLoading: false,
                });
            }))
    };

    render() {
        const { classes, startDate, endDate } = this.props;
        const { isLoading } = this.state;

        // Show loading icon while waiting for results from backend
        if (isLoading) return (
            <Grid item xs={12}>
                <Paper>
                    <Preloader/>
                </Paper>
            </Grid>
        );

        const { assessmentsCount } = this.state;

        const startDateAsText = moment(startDate).format("DD/MM/YYYY");
        const endDateAsText = moment(endDate).format("DD/MM/YYYY");

        return (
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <Card>
                        <CardContent align="center">
                            <Typography
                                className={classes.title}
                                color="textSecondary"
                                gutterBottom
                            >
                                Risk Rating History (All Reports)
                                <Tooltip
                                    classes={{
                                        tooltip: classes.htmlTooltip,
                                    }}
                                    title={
                                        <React.Fragment>
                                           <b>{'Shows a count of Assessments (All Reports) by Risk Rating each day for the date range ' + startDateAsText + ' to ' + endDateAsText}</b>.{' '}
                                           <br/>
                                        </React.Fragment>
                                    }
                                >
                                    <InfoOutlined color="disabled"/>
                                </Tooltip>
                            </Typography>

                            <Typography variant="h5" component="h2" style={{height: 200}}>
                                <BarChartAssessmentRiskRatings
                                    data={assessmentsCount}
                                    customised={false}
                                />
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

export default withRouter(withStyles(styles)(WidgetChartMyAllReportsAssessmentsByRiskRatingForDateRange));

import React from 'react';

// Material UI
import { Grid, Button, TextField, Input, InputLabel, Select, MenuItem } from '@mui/material';
import { withStyles } from '@mui/styles';

// Molecules
import FormErrorMessage from '../molecules/FormErrorMessage/FormErrorMessage';

// Utils
import * as Yup from "yup";
import { Formik, Form } from 'formik';

// API Services
import createAction from '../../api/ActionService';

const styles = theme => ({
    // Allows a form element within a full width grid element (e.g. xs={12}) to vary the width it occupies at various screen sizes
    singleColumn: {
        [theme.breakpoints.up('sm')]: {
            width: "48.5%"
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        }
    },
    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
});

const FatigueAssessmentActionForm = props => {
    const { notification, assessment, handleGetAssessment } = props;

    // We do this Yup Validation a little differrently than other components in MAFAT. This is because we need to
    // do conditional validation.
    let validationShape = {
        action_required: Yup.string().nullable().max(255).required("This is a required field"),
        controls: Yup.array().when("showActionOptions", {
            is: true,
            then: Yup.array().required("Please provide a reason for the 'Planned Dispatch Date' change").nullable().max(2000)
        }),
        comments: Yup.string().max(255).required("This is a required field"),
        supervisor_signature: Yup.string().max(255).required("This is a required field"),
    };

    return (
        <Formik
            // Set initial values of the form
            initialValues={
                {
                    submission: assessment.id,  // submission = assessment ID that we are creating the action record for.
                    action_required: "",
                    controls: [], // new Formik handles arrays/lists
                    comments: '',
                    supervisor_signature: '',

                }
            }

            onSubmit={(values, {setSubmitting}) => {

                const new_values = { ...values, controls: JSON.stringify(values.controls) }

                createAction(new_values)
                    .then((response) => {
                        // Refresh the Assessment details
                        handleGetAssessment();

                        // Notify user of successful submission
                        notification.success('Action saved successfully')

                    })
                    .catch((error) => {
                        console.log(error);
                        notification.error("Save failed (" + error + ")")
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
            validationSchema={
                // We do this Yup Validation a little differrently than other components in MAFAT. This is because we need to
                // do confitional validation. 'validationShape' is defined at the beginning of this function.
                Yup.object().shape(validationShape)
            }
            validateOnChange={false}
            enableReinitialize={true}
        >
        {formikProps => <FormComponent {...formikProps} {...props} />}
        </Formik>
    );
};

export default withStyles(styles)(FatigueAssessmentActionForm);

// The "controls" options
const options = [
  'Revisit Fatigue Assessment',
  'Buddy Up',
  'Remove from Safety Critical',
  'Encourage the use of alertness strategies',
  'Alternate Duties - Office Bound',
];

class FormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state={
        }
    }

    handleChangeMultiple = event => {
        // Converts multiple selections into a list/array and sets the 'controls' values variable in formik to the array.
        // Also converts the array to a JSON string.
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        this.props.setValues({
            ...this.props.values,
            controls: value,
        })
      };

    render() {
        const { lookups, isSubmitting } = this.props;

        // Formik
        const { setValues, values, handleBlur, handleChange } = this.props;

        return (
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            select
                            id="action_required"
                            name="action_required"
                            variant="outlined"
                            value={values.action_required}
                            onChange={(value) => {
                                let showActionOptionsVal = false;
                                if (value) {
                                    // If "Action Taken" is set to "Action has been taken" (1) then set a custom variable
                                    // to true, used for conditional validation.
                                    if (value.target.value === "1") {
                                        showActionOptionsVal = true;
                                    }
                                    setValues({
                                        ...values,
                                        // Set the value 'action_required' value in formik to the selected dropdown option.
                                        action_required: value.target.value,
                                        // Create a new value/variable which will be used in Yup conditional validation.
                                        showActionOptions: showActionOptionsVal
                                    })
                                }}
                            }
                            onBlur={handleBlur('action_required')}
                            label="Action Taken:"
                            disabled={isSubmitting}
                            fullWidth
                            SelectProps={{
                                displayEmpty: true
                            }}>
                            {lookups.actions.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <FormErrorMessage name="action_required"/>
                    </Grid>

                    {(values.action_required === 1) &&
                        <Grid item xs={12} sm={12}>
                            <InputLabel htmlFor="controls">Controls:</InputLabel>
                            <Select
                                multiple
                                native
                                value={this.state.name}
                                onChange={this.handleChangeMultiple}
                                input={<Input id="select-multiple"/>}
                                inputProps={{
                                    id: 'controls',
                                }}
                            >
                                {options.map(name => (
                                    <option key={name} value={name}>
                                        {name}
                                    </option>
                                ))}
                            </Select>
                            <FormErrorMessage name="controls"/>
                        </Grid>
                    }

                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="comments"
                            name="comments"
                            label="Comments (Please do not capture personal and sensitive information in these boxes, rather objective actions that were/will be taken.)"
                            value={values.comments}
                            onChange={handleChange('comments')}
                            onBlur={handleBlur('comments')}
                            disabled={isSubmitting}
                            InputLabelProps={{ shrink: Boolean(values.comments) }}
                            variant="outlined"
                            fullWidth
                            multiline
                            minRows="4"
                            />
                        <FormErrorMessage name="comments" />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="supervisor_signature"
                            name="supervisor_signature"
                            label="Supervisor Signature"
                            value={values.supervisor_signature}
                            onChange={handleChange('supervisor_signature')}
                            onBlur={handleBlur('supervisor_signature')}
                            disabled={isSubmitting}
                            InputLabelProps={{ shrink: Boolean(values.supervisor_signature) }}
                            variant="outlined"
                            fullWidth
                            />
                        <FormErrorMessage name="supervisor_signature" />
                    </Grid>

                </Grid>

                <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            disabled={isSubmitting}>Submit</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }
}

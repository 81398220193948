import React from 'react';

// Material UI
import { withStyles } from '@mui/styles';
import { Grid, Button,TextField, MenuItem, Typography, Tooltip, InputAdornment, FormControl, InputLabel, Select} from '@mui/material';
import { Help } from '@mui/icons-material';

// Components Shared
import FormErrorMessage from '../molecules/FormErrorMessage/FormErrorMessage';
import BooleanRadio from '../molecules/BooleanRadio';
import PeoplePicker from './PeoplePicker';

// Utils
import * as Yup from "yup";
import { Formik, Form, Field } from 'formik';
import * as moment from "moment";

// API Services
import {createAssessment} from '../../api/AssessmentService';


const styles = theme => ({
    // Override CSS for select dropdown icon due to bug here https://github.com/mui-org/material-ui/issues/17799#issuecomment-589678881
    override: {
        '& .MuiSelect-icon': {
                position: 'absolute', right: '45px',
            },
    },
    // Allows a form element within a full width grid element (e.g. xs={12}) to vary the width it occupies at various screen sizes
    singleColumn: {
        [theme.breakpoints.up('sm')]: {
            width: "48.5%"
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        }
    },
    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
});

const FatigueAssessmentCreateForm = props => {
    const { navigation, notification, user } = props;

    const setDefaultShiftDate = () => {
        // Returns todays date
        return moment().format("YYYY-MM-DD")
    };

    const setDefaultShiftTime = () => {
        // Returns the shifttime which was specified as default
        let starttimes = props.lookups.shiftstarttimes;

        // Loop through starttimes, find the one where the key 'default' is true and returns its id.
        for(let i in starttimes){
            if (starttimes[i].default) {
                return starttimes[i].id
            }
        }
    };

    const setDefaultShiftSupervisor = () => {
        // Returns the logged in users manager as an object.
        return {
            identifier: user.manager_id,
            displayName: user.manager_email
        }
    };

    return (
        <Formik
            // Set initial values of the form
            initialValues={
                {
                    hours_sleep_in_last_24Hours: '',
                    hours_sleep_in_last_48Hours: '',
                    hours_awake_till_end_of_shift: '',
                    do_you_feel_alert: '',
                    number_of_drinks_before_sleep: '',
                    gender: '',
                    any_medication_cause_unfit_for_work: '',
                    other_problems_affecting_concentration: '',
                    shift_supervisor: setDefaultShiftSupervisor(),
                    shift_start_date: setDefaultShiftDate(),
                    shift_start_time: setDefaultShiftTime(),
                    depot: '',
                }
            }

            onSubmit={(values, {setSubmitting}) => {

                if (values.any_medication_cause_unfit_for_work === "false" ) {
                    values.any_medication_cause_unfit_for_work = false
                }
                else if (values.any_medication_cause_unfit_for_work === "true" ) {
                    values.any_medication_cause_unfit_for_work = true
                }

                if (values.other_problems_affecting_concentration === "false" ) {
                    values.other_problems_affecting_concentration = false
                }
                else if (values.other_problems_affecting_concentration === "true" ) {
                    values.other_problems_affecting_concentration = true
                }

                // Get pk/id of shift supervisor selected from the object provided
                values.shift_supervisor = values.shift_supervisor.identifier;

                createAssessment(values)
                    .then((response) => {
                        // On success, redirect to the Asset Details page for the asset which was just created
                        navigation.onSubmitSuccess(response);
                    })
                    .catch((error) => {
                        console.log(error);
                        notification.error("Save failed (" + error + ")")
                    })
                    .finally(() => {
                        const isMounted = true;
                        if (isMounted) {
                            setSubmitting(false);
                        }
                    });
            }}
            validationSchema={
                Yup.object().shape({
                    // Validation of fields before sending to API
                    hours_sleep_in_last_24Hours: Yup
                        .string()
                        .required("This is a required field"),
                    hours_sleep_in_last_48Hours: Yup
                        .string()
                        .required("This is a required field"),
                    hours_awake_till_end_of_shift: Yup
                        .string()
                        .required("This is a required field"),
                    number_of_drinks_before_sleep: Yup
                        .number()
                        .required("This is a required field"),
                    do_you_feel_alert: Yup
                        .number()
                        .required("This is a required field"),
                    gender: Yup
                        .string()
                        .required("This is a required field"),
                    any_medication_cause_unfit_for_work: Yup
                        .boolean()
                        .required("This is a required field"),
                    other_problems_affecting_concentration: Yup
                        .boolean()
                        .required("This is a required field"),
                    depot: Yup
                        .string()
                        .max(50)
                        .required("This is a required field"),
                    shift_start_date: Yup
                        .string()
                        .required("This is a required field"),
                    shift_start_time: Yup
                        .string()
                        .required("This is a required field"),
                    shift_supervisor: Yup
                        .object()
                        .nullable()
                        .required("This is a required field"),
                })
            }
            validateOnChange={false}
            enableReinitialize={true}
        >
            {formikProps => <FormComponent {...formikProps} {...props} />}
        </Formik>
    );
};

export default withStyles(styles)(FatigueAssessmentCreateForm);

class FormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            disabled: true
        }
    }
    
    handleConsentChange = (e) => {
        if (e.target.checked === true){
            this.setState({
                disabled: false
            });
        }
        else {
            this.setState({
                disabled: true
            });
        }
      }  

    render() {
        const { classes, lookups, isSubmitting, handleSearchUsers, handleUpdateUserSearchText } = this.props;
        // Formik
        const { values, handleBlur, handleChange, setFieldValue } = this.props;

        return (
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">How many hours sleep have you had in the last 24 hours?</InputLabel>
                            <Select
                                id="hours_sleep_in_last_24Hours"
                                name="hours_sleep_in_last_24Hours"
                                value={values.hours_sleep_in_last_24Hours}
                                label="How many hours sleep have you had in the last 24 hours?"
                                onChange={handleChange('hours_sleep_in_last_24Hours')}
                                onBlur={handleBlur('hours_sleep_in_last_24Hours')}
                                disabled={isSubmitting}
                                fullWidth
                            >
                                {lookups.hours.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormErrorMessage name="hours_sleep_in_last_24Hours"/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <InputLabel>How many hours sleep have you had in the last 48 hours?</InputLabel>
                            <Select
                                id="hours_sleep_in_last_48Hours"
                                name="hours_sleep_in_last_48Hours"
                                value={values.hours_sleep_in_last_48Hours}
                                onChange={handleChange('hours_sleep_in_last_48Hours')}
                                onBlur={handleBlur('hours_sleep_in_last_48Hours')}
                                label="How many hours sleep have you had in the last 48 hours?"
                                disabled={isSubmitting}
                                fullWidth
                            >
                                {lookups.hours.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormErrorMessage name="hours_sleep_in_last_48Hours"/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <InputLabel>How long will you have been awake by the end of your shift?</InputLabel>
                            <Select
                                id="hours_awake_till_end_of_shift"
                                name="hours_awake_till_end_of_shift"
                                value={values.hours_awake_till_end_of_shift}
                                onChange={handleChange('hours_awake_till_end_of_shift')}
                                onBlur={handleBlur('hours_awake_till_end_of_shift')}
                                label="How long will you have been awake by the end of your shift?"
                                disabled={isSubmitting}
                                fullWidth
                            >
                                {lookups.hours.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormErrorMessage name="hours_awake_till_end_of_shift"/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <InputLabel>Do you feel alert?</InputLabel>
                            <Select
                                id="do_you_feel_alert"
                                name="do_you_feel_alert"
                                value={values.do_you_feel_alert}
                                onChange={handleChange('do_you_feel_alert')}
                                onBlur={handleBlur('do_you_feel_alert')}
                                label="Do you feel alert?"
                                disabled={isSubmitting}
                                fullWidth
                            >
                                {lookups.alertness.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormErrorMessage name="do_you_feel_alert"/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <InputLabel>How many alcoholic drinks did you have before your sleep?</InputLabel>
                            <Select
                                id="number_of_drinks_before_sleep"
                                name="number_of_drinks_before_sleep"
                                value={values.number_of_drinks_before_sleep}
                                onChange={handleChange('number_of_drinks_before_sleep')}
                                onBlur={handleBlur('number_of_drinks_before_sleep')}
                                label="How many alcoholic drinks did you have before your sleep?"
                                disabled={isSubmitting}
                                fullWidth
                            >
                                {lookups.numberOfDrinks.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormErrorMessage name="number_of_drinks_before_sleep"/>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <InputLabel>What is your sex?</InputLabel>
                            <Select
                                id="gender"
                                name="gender"
                                className={classes.override}
                                value={values.gender}
                                onChange={handleChange('gender')}
                                onBlur={handleBlur('gender')}
                                label="What is your sex?"
                                disabled={isSubmitting}
                                fullWidth
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <Tooltip
                                                classes={{
                                                    tooltip: classes.htmlTooltip,
                                                }}
                                                title={
                                                    <React.Fragment>
                                                        <Typography color="inherit">
                                                            Your response to this question is used in calculating the risk associated with number of drinks consumed before sleep
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                                >
                                                    <Help color='disabled'/>
                                                </Tooltip>
                                        </InputAdornment>,
                                    }}
                            >
                                {lookups.genders.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormErrorMessage name="gender"/>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Field name="any_medication_cause_unfit_for_work">
                                {({ field }) => (
                                <BooleanRadio
                                    {...field}
                                    label="Are you on any medication or other substances that could cause drowsiness or cause you to be unfit for work?"
                                    disabled={isSubmitting} />
                            )}
                            </Field>
                            <FormErrorMessage name="any_medication_cause_unfit_for_work" />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Field name="other_problems_affecting_concentration">
                        {({ field }) => (
                                <BooleanRadio
                                    {...field}
                                    label="Do you have any stress, health problems or other personal problems that are significantly affecting your concentration and/or sleep?"
                                    disabled={isSubmitting} />
                            )}
                        </Field>
                            <FormErrorMessage name="other_problems_affecting_concentration" />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="depot"
                            name="depot"
                            label="Sign On Location"
                            value={values.depot}
                            onChange={handleChange('depot')}
                            onBlur={handleBlur('depot')}
                            disabled={isSubmitting}
                            InputLabelProps={{ shrink: Boolean(values.depot) }}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Tooltip
                                            classes={{
                                                tooltip: classes.htmlTooltip,
                                            }}
                                            title={
                                                <React.Fragment>
                                                    <Typography color="inherit">Type your shift start location</Typography>
                                                </React.Fragment>
                                            }
                                            >
                                                <Help color='disabled'/>
                                            </Tooltip>
                                    </InputAdornment>,
                                }}
                            />
                            <FormErrorMessage name="depot" />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <TextField
                            select
                            id="shift_start_date"
                            name="shift_start_date"
                            variant="outlined"
                            className={classes.singleColumn}
                            value={values.shift_start_date}
                            onChange={handleChange('shift_start_date')}
                            onBlur={handleBlur('shift_start_date')}
                            label="Shift Start Date"
                            disabled={isSubmitting}
                            fullWidth
                            SelectProps={{
                                displayEmpty: true
                            }}>
                            {lookups.shiftstartdates.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <FormErrorMessage name="shift_start_date"/>
                    </Grid>

                     <Grid item xs={12} sm={12}>
                        <TextField
                            select
                            id="shift_start_time"
                            name="shift_start_time"
                            variant="outlined"
                            className={classes.singleColumn}
                            value={values.shift_start_time}
                            onChange={handleChange('shift_start_time')}
                            onBlur={handleBlur('shift_start_time')}
                            label="Shift Start Time"
                            disabled={isSubmitting}
                            fullWidth
                            SelectProps={{
                                displayEmpty: true
                            }}>
                            {lookups.shiftstarttimes.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <FormErrorMessage name="shift_start_time"/>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <PeoplePicker
                            loadOptions={handleSearchUsers}  // Function to search for users
                            onChange={value => {
                                setFieldValue("shift_supervisor", value.identifier); // On selection of user, set field value for formik.
                            }}
                            onInputChange={handleUpdateUserSearchText}  // Function to set search text state variable, which is then used when searching in the 'handleSearchUsers' function
                            value={values.shift_supervisor}
                            label={'Shift Supervisor'}
                            placeholder={null}
                        />
                        <FormErrorMessage name="shift_supervisor"/>
                    </Grid>
                </Grid>
                <br />
                <input type="checkbox" onChange={this.handleConsentChange} /> Please read and indicate your acceptance of the following declaration.<br />
I consent to BHP collecting, using and processing my personal information to manage BHP’s safe working environment.  Please see BHP’s <a href="https://www.bhp.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://spo.bhpbilliton.com/sites/DW/Pages/Privacy.aspx?CT=1665059601799&OR=OWA-NT&CID=3d371a96-67a5-e5b5-b5a8-aa583bef38ec" target="_blank" rel="noopener noreferrer">Global Privacy Notice </a> for BHP’s Workers for more detail about how we manage personal information.
           
                <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button
                            id="submitForm"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            disabled={this.state.disabled || isSubmitting}>Submit</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }
}

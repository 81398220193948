import 'react-app-polyfill/ie11';  // Required for IE11 base "support"
import React from 'react';
import { render } from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// import "@babel/polyfill";

// Material UI
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Card, CardContent, Grid, Typography } from "@mui/material";

// Auth
import MomentUtils from '@date-io/moment';
import MsalService from './auth/MsalService';

// Utils
import App from './App';
import configureAxios from './common/AxiosSetup';
import {isIE} from "./services/HelperBrowserChecks";

// API Services
import {getLoggedInUser} from './api/UserService';

// Log app details on app startup.
/* eslint-disable no-console */
console.log("App Name: MAFAT");
console.log("App Env: ", window.env.ADAL_CONFIG.app_env);
console.log("App Version: ", window.env.ADAL_CONFIG.app_ver);
console.log("App Version Commit: ", window.env.ADAL_CONFIG.git_commit);
console.log("App Deployed Date/Time (UTC): ", window.env.ADAL_CONFIG.ci_deploy_datetime);

// Injects the Auth token (via adal authorization) into all axios requests
configureAxios();

// Enables/Disables the need to authenticate to access the app.
// Note, the backend API will still need authentication
const DO_NOT_LOGIN = window.env.ADAL_CONFIG.app_env === "LOCAL" && window.env.DO_NOT_LOGIN;

const rootContainer = document.getElementById('root');

if (isIE()) {
  // If Internet Explorer is used, do not allow MAFAT to be used and advise the user to upgrade.
  console.log("Internet Explorer is not supported, please upgrade to a modern web browser.");
  render(
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs} utils={MomentUtils}>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6">
                                        BHP Fatigue Management Tool (MAFAT) only supports modern web browsers.
                    <br />
                    <br />
                  </Typography>
                  <Typography variant="subtitle1">
                                        Please use a modern web browser. Google Chrome or Mozilla Firefox are suggested.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </LocalizationProvider>

      {/* `#root# is the div element in `index.html` where this app is mounted. */}
    </BrowserRouter>
    , rootContainer)
}
else {
  // TODO: This should be improved. This is duplicated code to activate the no SSO workaround in local. It can be improved
  
  /* eslint-disable no-lonely-if */
  if (DO_NOT_LOGIN) {
    getLoggedInUser().then((response) => {

      // Log authenticated user on app startup.
      console.log("Logged in User: ", response.data.email);
      console.log("Logged in User Is App Admin: ", response.data.is_app_admin);
      console.log("Logged in User Is Manager: ", response.data.is_manager);
      console.log("Logged in User Is Shift Supervisor: ", response.data.is_supervisor);

      render(
        <BrowserRouter basename="mafat">
          <LocalizationProvider dateAdapter={AdapterDayjs} utils={MomentUtils}>
            {/* `App` component being passed the logged in user details */}
            <App user={response.data} />
          </LocalizationProvider>
          {/* `#root# is the div element in `index.html` where this app is mounted. */}
        </BrowserRouter>, rootContainer
      );
    });
  } else {
    // Require ActiveDirectory Authentication to access the app.
    // Will redirect the user to Azure Auth
    MsalService.runWithAdal(() => {
      // Gets the logged in user details
      getLoggedInUser().then((response) => {

        // Log authenticated user on app startup.
        console.log("Logged in User: ", response.data.email);
        console.log("Logged in User Is App Admin: ", response.data.is_app_admin);
        console.log("Logged in User Is Manager: ", response.data.is_manager);
        console.log("Logged in User Is Shift Supervisor: ", response.data.is_supervisor);

        render(
          <BrowserRouter basename="mafat">
            <LocalizationProvider dateAdapter={AdapterDayjs} utils={MomentUtils}>
              {/* `App` component being passed the logged in user details */}
              <App user={response.data} />
            </LocalizationProvider>

            {/* `#root# is the div element in `index.html` where this app is mounted. */}
          </BrowserRouter>
          , rootContainer);
      });
    }
    );
  }}

import React from 'react';
import {withRouter} from 'react-router-dom';

// Material UI
import { withStyles } from '@mui/styles'
import Chip from '@mui/material/Chip';


// Utils
import * as moment from 'moment';

// Styling overrides - used to override and set the styling/css of the page
const styles = theme => ({});

class AssessmentDetailsChip extends React.Component {

    render() {

        const { classes, label, colour, size, is_date } = this.props;

        const getChipStyle = () => {
            let chipStyle;

            if (colour === "blue") {
                chipStyle = '#65c1dc'
            } else if (colour === "green") {
                chipStyle = '#65b663'
            } else if (colour === "orange") {
                chipStyle = '#e0531f'
            }
            return chipStyle
        };

        const getLabelText = () => {
            let labelText;
            if (is_date) {
                labelText = moment(label).format("MMM Do YYYY")
            }
            else {
                labelText = label
            }

            return labelText.toUpperCase();
        };

        return (
            <Chip
                size={size}
                label={getLabelText()}
                variant="outlined"
                className={getChipStyle()}
                sx={{
                    '&.MuiChip-root': {
                        fontWeight: 700,
                        fontSize: '11px',
                        borderRadius: '5px',
                        fontFamily: "Arial, Helvetica, sans-serif",
                        borderColor: getChipStyle(),
                    },
                    '& .MuiChip-label': {
                        color: getChipStyle(),
                    }
                }}
            />
        );
    }
}

export default withRouter(withStyles(styles)(AssessmentDetailsChip));
import axios from 'axios';
import MsalService from '../auth/MsalService';

// eslint-disable-next-line import/prefer-default-export
export default function configureAxios() {
  axios.defaults.timeout = 2 * 60 * 1000;
  
  // ------ Apply token to the request header ------
  axios.interceptors.request.use(
    (request) =>
      MsalService.acquireToken().then(
        (response) => {
          const token = response;

          if (token !== '') {
            request.headers.Authorization = `Bearer ${token}`;
          }

          return request;
        },
        (error) => Promise.reject(error) // Token Expired. Perform redirect to login page here
      ),
    (error) => Promise.reject(error)
  );

};

import React from 'react';
import {FormControl, FormLabel, FormControlLabel, RadioGroup, Radio} from '@mui/material';


const BooleanRadio = props => {
    const {classes, label, name, error, disabled, value, onChange, onBlur} = props;

    return (
        <FormControl component="fieldset" error={error} disabled={disabled}>
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
                name={name}
                value={value !== null ? value.toString() : ''}
                onChange={onChange}
                onBlur={onBlur}
                row={true}>
                <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                />
                <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                />
            </RadioGroup>
        </FormControl>);
}

export default BooleanRadio;
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material UI
import { withStyles } from '@mui/styles';
import { MenuItem, Paper, TextField, Chip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { emphasize } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';

// Utils
import AsyncSelect from 'react-select/async';

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    input: {
        display: 'flex',
        padding: 4,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        paddingLeft: '10px',
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 5,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
});

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

const inputComponent = React.forwardRef((props, ref) => <div ref={ref} {...props} />);

function Control(props) {
    return (
        <TextField
            fullWidth
            variant="outlined"
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    ref: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

class PeoplePicker extends React.Component {
    render() {
        const { classes, theme, label, placeholder, isMulti, value, onChange, loadOptions, loadingMessage, noOptionsMessage, onInputChange } = this.props;

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
        };

        return (
            <AsyncSelect
                classes={classes}
                styles={selectStyles}
                textFieldProps={{
                    label: label,
                    InputLabelProps: {
                        shrink: true,
                    },
                }}
                getOptionLabel={({displayName}) => displayName}
                getOptionValue={({identifier}) => identifier}
                loadOptions={loadOptions} // Searching input/text
                components={components}
                value={value}  // value must be a object ie. {displayname: 'Stan Smith', identifier: 5}
                onChange={identifier => onChange({identifier})}  // When the user clicks on an item in the select dropdown
                onInputChange={inputValue => onInputChange(inputValue)}  // setting search state
                isMulti={isMulti}
                isClearable={true}
                placeholder={placeholder}
                loadingMessage={() => {
                    return loadingMessage
                }}
                noOptionsMessage={() => {
                    return noOptionsMessage
                }}
            />
        );
    }
}

PeoplePicker.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    loadOptions: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string,
    loadingMessage: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.object,
    // searchType: PropTypes.string,
};

PeoplePicker.defaultProps = {
    isMulti: false,
    loadingMessage: 'Searching...',
    noOptionsMessage: 'No results',
    label: 'Search...',
};

export default withStyles(styles, {withTheme: true})(PeoplePicker);

import axios from 'axios';
import appConfig from '../common/Config';

// =================================================================================
// Assessment Actions
// =================================================================================

// Get existing Action
// Existing actions are included within the Assessment serializer

// Create new Action
export default function createAction(payload) { axios.post(`${appConfig.apiHost}/ffw/actions/`, payload); }


import React, {Component} from 'react';
import "react-placeholder/lib/reactPlaceholder.css";
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Context
import AppContext from '../AppProvider';

// Material UI
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

// Components
import UnactionedAssessments from '../components/organisms/AssessmentsTableUnactioned';
import WidgetChartMyDirectReportsAssessmentsByRiskRatingForDateRange from '../components/organisms/WidgetChartMyDirectReportsAssessmentsByRiskRatingForDateRange';
import WidgetChartMyAllReportsAssessmentsByRiskRatingForDateRange from '../components/organisms/WidgetChartMyAllReportsAssessmentsByRiskRatingForDateRange';
import WidgetChartAllReportsTree from '../components/organisms/WidgetChartAllReportsTree';
import WidgetAssessmentsToRevisit from "../components/organisms/WidgetAssessmentsToRevisit";

// Utils
import * as moment from "moment";
import axios from "axios";

// API
import {getMyAssessmentsToRevisit} from "../api/UserService";

const styles = theme => ({
    root: {
        flexGrow: 1
    },
});

class Dashboard extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            assessmentsToRevisitData: null,
        }
    }

    handleGetAssessmentsToRevisit() {
        axios.all(
        [
            getMyAssessmentsToRevisit(),
        ])
        .then(axios.spread((assessmentsToRevisitData) => {
                this.setState({
                    assessmentsToRevisitData: assessmentsToRevisitData.data,
                });
            })
        )
    }

    componentDidMount() {
        this.handleGetAssessmentsToRevisit();
    }

    render() {
        const loggedInUser = this.context.user;

        const { classes } = this.props;
        const { assessmentsToRevisitData } = this.state;

        return (
            <div className={'page ' + classes.root}>
                <Grid container spacing={3}>
                    {(loggedInUser.is_manager) && (
                        <Grid item xs={12}>
                            <Grid container>

                                <Grid item xs={4} className={classes.root}>
                                    <WidgetChartMyDirectReportsAssessmentsByRiskRatingForDateRange
                                        startDate={moment(new Date()).subtract(10,'days').format("YYYY-MM-DD")}
                                        endDate={moment(new Date()).add(0,'days').format("YYYY-MM-DD")}
                                    />
                                </Grid>

                                <Grid item xs={8} className={classes.root}>
                                    <WidgetChartMyAllReportsAssessmentsByRiskRatingForDateRange
                                        startDate={moment(new Date()).subtract(30,'days').format("YYYY-MM-DD")}
                                        endDate={moment(new Date()).add(0,'days').format("YYYY-MM-DD")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <UnactionedAssessments/>
                            </Grid>

                            <Grid item xs={4}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <WidgetAssessmentsToRevisit
                                            assessmentsData={assessmentsToRevisitData}
                                        />
                                    </Grid>
                                    {(loggedInUser.is_manager) && (
                                        <Grid item xs={12}>
                                            <WidgetChartAllReportsTree
                                                startDate={moment(new Date()).subtract(30,'days').format("YYYY-MM-DD")}
                                                endDate={moment(new Date()).add(0,'days').format("YYYY-MM-DD")}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Dashboard));

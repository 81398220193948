import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@mui/styles'
import IconButton from '@mui/material/IconButton';
import {HelpOutlineOutlined, AccountCircleOutlined} from '@mui/icons-material';
import {Typography, Tooltip} from '@mui/material';

const styles = theme => ({
    font: {
        fontSize: 14
    },
    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
});

export class LoggedInUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            displayName: props.user.fullname,
            anchorEl: null,
        };
    }

    openLinkInNewTab = (url) => {
        // Opens the provided url in a new browser tab
        var win = window.open(url, '_blank');
        win.focus();
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    render() {
        const {classes} = this.props;
        const {anchorEl} = this.state;
        const open = Boolean(anchorEl);

        return (
            <span>
                <IconButton
                    aria-owns={open ? 'menu-appbar' : undefined}
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircleOutlined/>
                    <span className={classes.font}> {this.state.displayName} </span>
                </IconButton>
                <IconButton
                    aria-label="Open User Docs in new browser tab"
                    onClick={() => this.openLinkInNewTab('/api/docs/docs-access?token=' + window.env.ADAL_CONFIG.docs_token)}>
                    <Tooltip
                        classes={{
                            tooltip: classes.htmlTooltip,
                        }}
                        title={
                            <React.Fragment>
                                <Typography color="inherit">Help</Typography>
                                <b>{'Click to view MAFAT User documentation'}</b>.{' '}<br></br>
                                <br></br>
                                <b>{'MAFAT Version: '}{window.env.ADAL_CONFIG.app_ver}</b>
                            </React.Fragment>
                        }
                    >
                        <HelpOutlineOutlined/>
                    </Tooltip>
                </IconButton>
            </span>
        );
    }
}

LoggedInUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoggedInUser);
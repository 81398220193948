import React from 'react';
import {withRouter} from 'react-router-dom';
// Material UI
import { withStyles } from '@mui/styles';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

// Styling overrides - used to override and set the styling/css of the page
const styles = theme => ({});

class RiskRatingCountChip extends React.Component {

    render() {

        const {classes, label, RiskRatingName, size} = this.props;

        const getChipStyle = () => {
            let chipClass;

            if (RiskRatingName === "Low") {
                chipClass = '#5cb85c'
            } else if (RiskRatingName === "Medium") {
                chipClass = '#f0ad4e'
            } else if (RiskRatingName === "High") {
                chipClass =  '#d9534f'
            }
            return chipClass
        };

        return (
            <Tooltip
                classes={{
                    tooltip: classes.htmlTooltip,
                }}
                title={
                    <React.Fragment>
                        <b>{label + ' ' + RiskRatingName + ' risks found'}</b>{' '}
                    </React.Fragment>
                }
            >
                <Chip
                    size={size}
                    label={label}
                    variant="outlined"
                    sx={{
                        '&.MuiChip-root': {
                            fontWeight: 700,
                            fontSize: '11px',
                            borderRadius: '5px',
                            fontFamily: "Arial, Helvetica, sans-serif",
                            borderColor: getChipStyle(),
                        },
                        '& .MuiChip-label': {
                            color: getChipStyle(),
                        }
                    }}
                />
            </Tooltip>
        );
    }
}

export default withRouter(withStyles(styles)(RiskRatingCountChip));
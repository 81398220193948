import axios from 'axios';
import appConfig from '../common/Config';

// =================================================================================
// Assessments
// =================================================================================

// Get existing Assessment
export const getAssessment = (assessmentId) => axios.get(`${appConfig.apiHost}/ffw/assessments/${assessmentId}/`);

// Get all/multiple Assessments
export const getAssessments = (payload) => axios.get(`${appConfig.apiHost}/ffw/assessments/`, payload);

// Create new Asset
export const createAssessment = (payload) => axios.post(`${appConfig.apiHost}/ffw/assessments/`, payload);


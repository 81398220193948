import React, {Component} from 'react';
import {forwardRef} from 'react';
import {Link as RouterLink} from "react-router-dom";

// Context
import AppContext from '../AppProvider';

// Material UI
import {Link, Grid, Button, Paper, Typography, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';

import {InfoOutlined} from "@mui/icons-material";
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';

// Utils
import MaterialTable from '@material-table/core';
import axios from 'axios';

// Components Shared
import Preloader from '../components/atoms/PreLoader/Preloader';
import RiskRatingScoreChip from '../components/atoms/RiskRatingScoreChip';
import ActionRequiredCountChip from '../components/atoms/ActionRequiredCountChip';

// API Services
import {getAssessments} from "../api/AssessmentService";
import * as moment from "moment";
import DateRangePickerDialog from "../components/organisms/DateRangePickerDialog";
import WidgetBarChartAssessmentsByRiskRatingForDateRange from "../components/organisms/WidgetBarChartAssessmentsByRiskRatingForDateRange";
import {getAssessmentsCountByEmployeeByRiskRatingForSupervisor} from "../api/WidgetService";
import dayjs from 'dayjs';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 420,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
});

class ReportShiftSupervisorAssessments extends Component {
    static contextType = AppContext;

    state = {
        isLoading: true,

        isDateRangePickerOpen: false,

        startDate: moment(new Date()).subtract(7,'days').format("YYYY-MM-DD"),
        endDate: moment(new Date()).add(0,'days').format("YYYY-MM-DD"),
    };

    handleSetStartDate = (value) => {
        this.setState(() => ({
            startDate: moment(value).format("YYYY-MM-DD"),
        }))
    };

    handleSetEndDate = (value) => {
        this.setState(() => ({
            endDate: moment(value).format("YYYY-MM-DD"),
        }))
    };

    handleClickDateRangePickerOpen = () => {
        this.setState(() => ({
            isDateRangePickerOpen: true,
        }))
    };

    handleClickDateRangePickerFilter = () => {
        this.setState(() => ({
            isDateRangePickerOpen: false,
        }));
        this.handleGetAssessments();
        this.handleGetAssessmentsCountByEmployeeByRiskRatingForSupervisor();
    };

    handleClickDateRangePickerClose = () => {
        this.setState(() => ({
            isDateRangePickerOpen: false,
        }));
    };

    // ===================================================================================================
    // Widgets data
    // ===================================================================================================
    handleGetAssessmentsCountByEmployeeByRiskRatingForSupervisor = () => {

        this.setState({
            // Clear the data, causing the 'loading icon' to show
            assessmentsCountByEmployeeByRiskRatingForDateRangeForSupervisor: null,
        });

        let queryPayload = {
            params: {
                // shiftsupervisor_user_id: this.context.user.id,
                start_date: this.state.startDate,
                end_date: this.state.endDate,
            }
        };

        axios.all(
            [
                getAssessmentsCountByEmployeeByRiskRatingForSupervisor(queryPayload),
            ])
            .then(axios.spread((assessmentsCountByEmployeeByRiskRatingForDateRangeForSupervisor) => {
                this.setState({
                    assessmentsCountByEmployeeByRiskRatingForDateRangeForSupervisor: assessmentsCountByEmployeeByRiskRatingForDateRangeForSupervisor.data,
                });
            }))
    };

    // ===================================================================================================
    // Assessments
    // ===================================================================================================
    handleGetAssessments = () => {
        let queryPayload = {
            params: {
                filter_type: 'ReportShiftSupervisorAssessments',
                start_date: this.state.startDate,
                end_date: this.state.endDate,
            }
        };

        axios.all(
            [
                getAssessments(queryPayload),
            ])
            .then(axios.spread((assets) => {
                    this.setState({
                        assessments: assets.data.results,
                        isLoading: false,
                    });
                })
            )
    };

    componentDidMount() {
        this.handleGetAssessments();
        this.handleGetAssessmentsCountByEmployeeByRiskRatingForSupervisor();
    };

    render() {
        // Show loading icon while waiting for results from backend
        if (this.state.isLoading) return (
            <Grid item xs={12}>
                <Paper>
                    <Preloader/>
                </Paper>
            </Grid>
        );

        const { assessments, startDate, endDate, isDateRangePickerOpen, assessmentsCountByEmployeeByRiskRatingForDateRangeForSupervisor } = this.state;
        const { classes } = this.props;

        const startDateAsText = moment(startDate).format("DD/MM/YYYY");
        const endDateAsText = moment(endDate).format("DD/MM/YYYY");

        return (
            <React.Fragment>
                {(isDateRangePickerOpen) &&
                    <DateRangePickerDialog
                        startDate={dayjs(startDate)}
                        endDate={dayjs(endDate)}
                        onStartDateChange={this.handleSetStartDate}
                        onEndDateChange={this.handleSetEndDate}
                        onFilterClick={this.handleClickDateRangePickerFilter}
                        onFilterCloseClick={this.handleClickDateRangePickerClose}
                    />
                }
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} align={"left"}>
                        <Typography variant="caption" display={"inline"}>
                            Date Range:&nbsp;
                        </Typography>
                        <Typography variant="subtitle1" display={"inline"}>
                            &nbsp;
                            {moment(startDate).format("ll")} -> {moment(endDate).format("ll")}
                            &nbsp;
                            <Button variant="outlined" color="secondary" onClick={this.handleClickDateRangePickerOpen}>
                                <Edit/>
                            </Button>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <WidgetBarChartAssessmentsByRiskRatingForDateRange
                            data={assessmentsCountByEmployeeByRiskRatingForDateRangeForSupervisor}
                            tableTitleTooltipText={
                                <React.Fragment>
                                    <b>
                                       {'Shows a count of Assessments by Risk Rating, by employee where you are the Shift Supervisor'}
                                    </b>
                                    <ul>
                                        <li><b>{'Assessments Criteria:'}</b>{' '}
                                            <ul>
                                                <li><b>{'Shift Supervisor = You'}</b>{' '}</li>
                                                <li><b>{'Shift Date (Range) = ' + startDateAsText + ' to ' + endDateAsText}</b>{' '}</li>
                                                <li><b>{'Risk Rating = Low, Medium, High'}</b>{' '}</li>
                                            </ul>
                                        </li>
                                        <li><b>{'Grouped By:'}</b>{' '}
                                            <ul>
                                                <li><b>{'Employee'}</b>{' '}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </React.Fragment>
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <MaterialTable
                            columns={[
                                {
                                    title: 'Employee',
                                    field: 'user_full_name',
                                    render: (rowData, group) => {
                                        if (group === "group"){
                                            // Use different values when grouped
                                            return rowData
                                        }
                                        else {
                                            return <React.Fragment>{rowData.user_full_name} ({rowData.user_email})</React.Fragment>
                                        }
                                    }
                                },
                                {
                                    title: 'Shift Date',
                                    field: 'shift_start_date',
                                    defaultGroupOrder: 1,
                                },
                                {
                                    title: 'Shift Supervisor',
                                    field: 'shift_supervisor_full_name',
                                    render: (rowData, group) => {
                                        if (group === "group"){
                                            // Use different values when grouped
                                            return rowData
                                        }
                                        else {
                                            return <React.Fragment>{rowData.shift_supervisor_full_name} ({rowData.shift_supervisor_email})</React.Fragment>
                                        }
                                    }
                                },
                                {
                                    title: 'Risk',
                                    field: 'risk_rating',
                                    // Note: rowdata only contains the label when grouping
                                    render: (rowData, group) => {
                                        if (group === "group"){
                                            // Use different values when grouped
                                            return <RiskRatingScoreChip
                                                size="small"
                                                label={rowData}
                                                RiskRatingName={rowData}
                                            />
                                        }
                                        else {
                                            return <RiskRatingScoreChip
                                                size="small"
                                                label={rowData.risk_rating}
                                                RiskRatingName={rowData.risk_rating}
                                                RiskScore={rowData.risk_score}
                                            />
                                        }
                                    }
                                },
                                {
                                    title: 'Action Taken',
                                    field: 'action_taken', // custom field name (not in data model)
                                    grouping: false,
                                    sorting: false,
                                    render: rowData => {
                                        let displayVal = "";

                                        // Check if any actions have been recorded for this assessment
                                        if (rowData.actions.length > 0) {
                                            // Display the action name taken
                                            if (rowData.actions[0].action_required_name) {
                                                displayVal = rowData.actions[0].action_required_name;
                                            }
                                        }
                                        // If risk rating is low then no action is required
                                        else if (rowData.risk_rating === "Low") {
                                            displayVal = "No action required";
                                        }
                                        else {
                                            return <ActionRequiredCountChip
                                                size={"small"}
                                                label={"Action required"}
                                            />
                                        }
                                        return displayVal
                                    }
                                },
                                {
                                    title: '',
                                    field: 'action',  // custom field name (not in data model)
                                    render: rowData => {
                                        return <Link component={RouterLink} to={"/assessment/" + rowData.id}>View</Link>
                                    }
                                },
                            ]}
                            data={assessments}
                            title={
                                <React.Fragment>
                                    <Typography>
                                        Assessments where I am Shift Supervisor
                                        <Tooltip
                                            classes={{
                                                tooltip: classes.htmlTooltip,
                                            }}
                                            title={
                                                <React.Fragment>
                                                    Shows Assessments completed by staff where you where specified as their Shift Supervisor.
                                                    <ul>
                                                        <li><b>{'Assessments Criteria:'}</b>{' '}
                                                            <ul>
                                                                <li><b>{'Shift Supervisor = You'}</b>{' '}</li>
                                                                <li><b>{'Shift Start Date = ' + startDateAsText + ' to ' + endDateAsText}</b>.{' '}</li>
                                                                <li><b>{'Risk Rating = Low, Medium, High'}</b>{' '}</li>
                                                            </ul>
                                                        </li>
                                                        <li><b>{'Grouped By (defaults):'}</b>{' '}
                                                            <ul>
                                                                <li><b>{'Shift Date'}</b>{' '}</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </React.Fragment>
                                            }
                                        >
                                            <InfoOutlined color='disabled'/>
                                        </Tooltip>
                                    </Typography>
                                </React.Fragment>
                            }
                            icons={tableIcons}
                            options={{
                                grouping: true,
                                defaultExpanded: true,
                                pageSize: 50,
                                pageSizeOptions: [20, 50, 100, 200],
                                emptyRowsWhenPaging: false,
                            }}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(ReportShiftSupervisorAssessments);
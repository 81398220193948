import React, { useContext } from "react";
import { Drawer, Typography, Divider } from "@mui/material";
import { Switch } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ThemeColours from "./theme/ThemeColours";
import LayoutContext from "./LayoutContext";

const styles = (theme) => ({
  paper: {
    minWidth: "250px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 16px",
    ...theme.mixins.toolbar,
  },
  settingItem: {
    marginBottom: "16px",
  },
  content: {
    padding: "16px",
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  switchWrapper: {
    display: "flex",
    flexDirection: "row",
    "& .label": {
      flex: 1,
      display: "flex",
      alignItems: "center",
    },
  },
});

function SettingPanel(props) {
  const { classes, theme } = props;

  console.log("SettingPanel: " + theme.palette.mode);

  const layoutContext = useContext(LayoutContext);

  const toggleNightMode = (event) => {
    layoutContext.updateTheme(
      "type",
      event.target.value === "dark" ? "light" : "dark"
    );
  };

  return (
    <Drawer
      variant={props.variant}
      anchor="right"
      open={props.open}
      onClose={props.onClose}
      classes={{ paper: classes.paper }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <div className={classes.toolbar}>
        <div className={classes.logo}>
          <Typography variant="subheading">Application Settings</Typography>
        </div>
      </div>
      <Divider />
      <div className={classes.content}>
        <div className={classes.settingItem}>
          <div>
            <Typography variant="caption">Theme Colour</Typography>
          </div>
          <ThemeColours />
        </div>
        <div className={classes.switchWrapper}>
          <div className={"label"}>
            <Typography variant="caption">Night Mode</Typography>
          </div>
          <Switch
            checked={theme.palette.mode === "dark" ? true : false}
            value={theme.palette.mode}
            onChange={toggleNightMode}
          />
        </div>
      </div>
    </Drawer>
  );
}

export default withStyles(styles, { withTheme: true })(SettingPanel);

import React from 'react';
import {withRouter} from 'react-router-dom';

// Material UI
import { withStyles } from '@mui/styles'
import Chip from '@mui/material/Chip';

// Styling overrides - used to override and set the styling/css of the page
const styles = theme => ({});

class RiskRatingScoreChip extends React.Component {

    render() {

        const {label, RiskRatingName, RiskScore, size, prefix, suffix} = this.props;

        const getChipStyle = () => {
            let ChipStyle;

            if (RiskRatingName === "Low") {
                ChipStyle = '#5cb85c'
            } else if (RiskRatingName === "Medium") {
                ChipStyle = '#f0ad4e'
            } else if (RiskRatingName === "High") {
                ChipStyle = '#d9534f'
            }
            return ChipStyle
        };

        const getLabelText = () => {
            let labelText = '';

            if (RiskScore >= 0) {
                labelText = label + " (" + RiskScore + ")"
            }
            else {
                labelText = label
            }

            if (prefix) {
                labelText = prefix + labelText
            }

            if (suffix) {
                labelText = labelText + suffix
            }

            labelText = labelText.toUpperCase();

            return labelText
        };

        return (
            <Chip
                size={size}
                label={getLabelText()}
                variant="outlined"
                sx={{
                    '&.MuiChip-root': {
                        fontWeight: 700,
                        fontSize: '11px',
                        borderRadius: '5px',
                        fontFamily: "Arial, Helvetica, sans-serif",
                        borderColor: getChipStyle(),
                    },
                    '& .MuiChip-label': {
                        color: getChipStyle(),
                    }
                }}
            />
        );
    }
}

export default withRouter(withStyles(styles)(RiskRatingScoreChip));
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import AppContext from "../../AppProvider";

// Material UI
import { withStyles } from '@mui/styles'
import {Grid, Typography, Card, CardContent, Tooltip, Paper} from '@mui/material';
import {InfoOutlined} from '@mui/icons-material';

// Components Shared
import Preloader from "../atoms/PreLoader/Preloader";
import BarChartAssessmentRiskRatings from '../molecules/BarChartAssessmentRiskRatings/BarChartAssessmentRiskRatings';

const styles = theme => ({
    cardContent: {
        paddingBottom: '16px !important',
    },
    actionContent: {
        margin: '-2px',
    },
    icon: {
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    iconActive: {
        color: theme.palette.primary.main,
    },
    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 420,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
});

class WidgetBarChartAssessmentsByRiskRatingForDateRange extends Component {
    static contextType = AppContext;

    render() {
        const { classes, data, tableTitleTooltipText } = this.props;

        // Show loading icon while waiting for results from backend
        if (!data) return (
            <Grid item xs={12} className={classes.root}>
                <Paper>
                    <Preloader/>
                </Paper>
            </Grid>
        );

        return (
            <Grid container justifyContent="center">
                <Grid item xs={12} className={classes.root}>
                    <Card>
                        <CardContent align="center">
                            <Typography
                                className={classes.title}
                                color="textSecondary"
                                gutterBottom
                            >
                                Risk Ratings By Employee
                                <Tooltip
                                    classes={{
                                        tooltip: classes.htmlTooltip,
                                    }}
                                    title={tableTitleTooltipText}
                                >
                                    <InfoOutlined color="disabled"/>
                                </Tooltip>
                            </Typography>

                            <Typography variant="h5" component="h2" style={{height: 200}}>
                                <BarChartAssessmentRiskRatings
                                    data={data}
                                    customised={true}
                                    interval={0}
                                />
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

export default withRouter(withStyles(styles)(WidgetBarChartAssessmentsByRiskRatingForDateRange));
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import AppContext from "../../AppProvider";

// Material UI
import { withStyles } from '@mui/styles'
import {Grid, Typography, Card, CardContent, Tooltip, Paper} from '@mui/material';
import {InfoOutlined} from '@mui/icons-material';

// Utils
import * as moment from "moment";

// Components Shared
import Preloader from "../atoms/PreLoader/Preloader";
import BarChartAssessmentRiskRatings from "../molecules/BarChartAssessmentRiskRatings/BarChartAssessmentRiskRatings";

const styles = theme => ({
    root: {
        paddingRight: theme.spacing(2),
    },
    cardContent: {
        paddingBottom: '16px !important',
    },
    actionContent: {
        margin: '-2px',
    },
    icon: {
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    iconActive: {
        color: theme.palette.primary.main,
    },
    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
});

class WidgetChartUserProfileAssessmentsByRiskRatingForDateRange extends Component {
    static contextType = AppContext;

    render() {
        const { classes, startDate, endDate, user, data } = this.props;

        const startDateAsText = moment(startDate).format("DD/MM/YYYY");
        const endDateAsText = moment(endDate).format("DD/MM/YYYY");

        // Show loading icon while waiting for results from backend
        if (!data) return (
            <Grid item xs={12} className={classes.root}>
                <Paper>
                    <Preloader/>
                </Paper>
            </Grid>
        );

        return (
            <Grid container justifyContent="center">
                <Grid item xs={12} className={classes.root}>
                    <Card>
                        <CardContent align="center">
                            <Typography
                                className={classes.title}
                                color="textSecondary"
                                gutterBottom
                            >
                                Risk Rating History
                                <Tooltip
                                    classes={{
                                        tooltip: classes.htmlTooltip,
                                    }}
                                    title={
                                        <React.Fragment>
                                           <b>{'Shows a count of Assessments (completed by "' + user.email + '") by Risk Rating each day for the date range ' + startDateAsText + ' to ' + endDateAsText}</b>.{' '}
                                        </React.Fragment>
                                    }
                                >
                                    <InfoOutlined color="disabled"/>
                                </Tooltip>
                            </Typography>

                            <Typography variant="h5" component="h2" style={{height: 200}}>
                                <BarChartAssessmentRiskRatings
                                    data={data}
                                />
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

export default withRouter(withStyles(styles)(WidgetChartUserProfileAssessmentsByRiskRatingForDateRange));
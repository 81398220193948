import React, {Component} from 'react';
import {forwardRef} from 'react';

// Context
import AppContext from '../../AppProvider';

// Material UI
import { withStyles } from '@mui/styles';
import { Link, Typography } from '@mui/material';
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';

// Utils
import MaterialTable from '@material-table/core';

// Components Shared
import RiskRatingScoreChip from '../atoms/RiskRatingScoreChip';
import ManagerChip from "../atoms/ManagerChip";
import {Link as RouterLink} from "react-router-dom";
import ActionRequiredCountChip from "../atoms/ActionRequiredCountChip";
import RiskRatingCountChip from "../atoms/RiskRatingCountChip";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 420,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
});

class AssessmentsTableHierarchy extends Component {
    static contextType = AppContext;

    render() {
        const loggedInUser = this.context.user;
        const { manager } = this.props;

        return (

            <div style={{maxWidth: '100%'}}>
                <MaterialTable
                    columns={[
                        {
                            title: 'Employee',
                            field: 'user_full_name',
                            render: (rowData, group) => {
                                if (group === "group"){
                                    // Use different values when grouped
                                    return rowData
                                }
                                else {
                                    if (loggedInUser.is_manager) {
                                        return <Link component={RouterLink} to={"/userprofile/" + rowData.user_id}>{rowData.user_full_name} ({rowData.user_email})</Link>
                                    }
                                    else {
                                        return <React.Fragment>{rowData.user_full_name} ({rowData.user_email})</React.Fragment>
                                    }
                                }
                            }
                        },
                        {
                            title: 'Shift Date',
                            field: 'shift_start_date',
                            defaultGroupOrder: 1,
                        },
                        {
                            title: 'Shift Time (24h)',
                            field: 'shift_start_time'
                        },
                        {
                            title: 'Shift Supervisor',
                            field: 'shift_supervisor_full_name',
                            render: (rowData, group) => {
                                if (group === "group"){
                                    // Use different values when grouped
                                    return rowData
                                }
                                else {
                                    return <React.Fragment>{rowData.shift_supervisor_full_name} ({rowData.shift_supervisor_email})</React.Fragment>
                                }
                            }
                        },
                        {
                            title: 'Risk',
                            field: 'risk_rating',
                            // Note: rowdata only contains the label when grouping
                            render: (rowData, group) => {
                                if (group === "group"){
                                    // Use different values when grouped
                                    return <RiskRatingScoreChip
                                        size="small"
                                        label={rowData}
                                        RiskRatingName={rowData}
                                    />
                                }
                                else {
                                    return <RiskRatingScoreChip
                                        size="small"
                                        label={rowData.risk_rating}
                                        RiskRatingName={rowData.risk_rating}
                                        RiskScore={rowData.risk_score}
                                    />
                                }
                            }
                        },
                        {
                            title: 'Action Taken',
                            field: 'action_taken', // custom field name (not in data model)
                            grouping: false,
                            sorting: false,
                            render: rowData => {
                                let displayVal = "";

                                // Check if any actions have been recorded for this assessment
                                if (rowData.actions.length > 0) {
                                    // Display the action name taken
                                    if (rowData.actions[0].action_required_name) {
                                        displayVal = rowData.actions[0].action_required_name;
                                    }
                                }
                                // If risk rating is low then no action is required
                                else if (rowData.risk_rating === "Low") {
                                    displayVal = "No action required";
                                }
                                else {
                                    return <ActionRequiredCountChip
                                        size={"small"}
                                        label={"Action required"}
                                    />
                                }
                                return displayVal
                            }
                        },
                        {
                            title: '',
                            field: 'action',  // custom field name (not in data model)
                            render: rowData => {
                                return <Link component={RouterLink} to={"/assessment/" + rowData.id}>View</Link>
                            }
                        },
                    ]}
                    data={manager.assessments}
                    title={
                        <React.Fragment>
                            <Typography variant="h6">
                                <ManagerChip label={'Manager: ' + manager.full_name + ' (' + manager.email + ')'}/> Assessments (Direct Reports)
                                &nbsp;
                                <RiskRatingCountChip
                                    size="small"
                                    label={manager.assessments_count_low}
                                    RiskRatingName={'Low'}
                                />
                                &nbsp;
                                <RiskRatingCountChip
                                    size="small"
                                    label={manager.assessments_count_medium}
                                    RiskRatingName={'Medium'}
                                />
                                &nbsp;
                                <RiskRatingCountChip
                                    size="small"
                                    label={manager.assessments_count_high}
                                    RiskRatingName={'High'}
                                />
                                {(manager.assessments_count_requiring_action > 0) && (
                                    <React.Fragment>
                                        &nbsp;
                                        <ActionRequiredCountChip
                                            size={"small"}
                                            label={"require action"}
                                            countRequiringAction={manager.assessments_count_requiring_action}
                                        />
                                    </React.Fragment>
                                )}
                            </Typography>
                        </React.Fragment>
                    }
                    icons={tableIcons}
                    options={{
                        grouping: true,
                        defaultExpanded: true,
                        pageSize: 50,
                        pageSizeOptions: [20, 50, 100, 200],
                        emptyRowsWhenPaging: false,
                    }}
                />
            </div>
        )
    }
}

export default withStyles(styles)(AssessmentsTableHierarchy);
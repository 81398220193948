import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import { NavLink } from "react-router-dom";
import withStyles from '@mui/styles/withStyles';


const styles = (theme) => ({
  icon: {
    color: "white",
  },
  link: {
    color: "white",
    textDecoration: "none",
    "&.selected": {
      color: theme.palette.secondary["500"],
      fontWeight: "bold",
    },
    "&.selected $icon": {
      color: theme.palette.secondary["500"],
    },
  },
});

const MenuItems = (props) => {
  const { classes } = props;
  return (
    <List>
      {props.navigationRoutes.map((r, i) => {
        return (
          <NavLink
            className={classes.link}
            key={i}
            to={r.path}
            exact
            activeClassName="selected"
          >
            <ListItem>
              <ListItemIcon className={classes.icon}>
                <r.icon />
              </ListItemIcon>
              <ListItemText
                disableTypography={true}
                className={classes.label}
                primary={r.name}
              ></ListItemText>
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
};

export default withStyles(styles)(MenuItems);

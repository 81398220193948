import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Material UI
import { withStyles } from '@mui/styles'
import { Typography, Grid, Button } from '@mui/material';
import { Edit } from '@mui/icons-material';

// Context
import AppContext from '../AppProvider';

// Components Shared
import NotificationSnackbar from '../components/molecules/NotificationSnackbar/NotificationSnackbar';
import Preloader from '../components/atoms/PreLoader/Preloader';
import AssessmentsTableUserProfile from '../components/organisms/AssessmentsTableUserProfile';
import UserProfileManagerWidget from '../components/organisms/UserProfileManagerWidget';
import DateRangePickerDialog from "../components/organisms/DateRangePickerDialog";
import WidgetChartUserProfileAssessmentsByRiskRatingForDateRange from "../components/organisms/WidgetChartUserProfileAssessmentsByRiskRatingForDateRange";

// Utils
import axios from 'axios';
import * as moment from "moment";

// API Services
import getUserProfileDetails from '../api/UserProfileService';
import {getAssessments} from "../api/AssessmentService";
import {getUserProfileAssessmentsCountByRiskRatingForDateRange} from "../api/WidgetService";
import TerminatedUserChip from "../components/atoms/TerminatedUserChip";
import dayjs from 'dayjs';

// Styling overrides - used to override and set the styling/css of the page
const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        chip: {
            margin: theme.spacing(),
        },
    },
    badge: {
        top: '45%',
        right: -15,
    },
    column: {
        flex: 1,
    },
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{padding: 8 * 3}}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class UserProfileDetails extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {

            isLoading: true,

            isLoadingAssessments: true,

            isDateRangePickerOpen: false,

            startDate: moment(new Date()).subtract(30,'days').format("YYYY-MM-DD"),
            endDate: moment(new Date()).add(0,'days').format("YYYY-MM-DD"),
        }
    }

    // ===================================================================================================
    // Notifications
    // ===================================================================================================
    displayNotification = variant => message => {
        this.setState({
            notificationDisplayed: true,
            notificationMessage: message,
            notificationVariant: variant
        });
    };

    handleCloseNotification = () => {
        this.setState({
            notificationDisplayed: false
        });
    };

    // ===================================================================================================
    // Date Range Picker
    // ===================================================================================================
    handleSetStartDate = (value) => {
        this.setState(() => ({
            startDate: moment(value).format("YYYY-MM-DD"),
        }))
    };

    handleSetEndDate = (value) => {
        this.setState(() => ({
            endDate: moment(value).format("YYYY-MM-DD"),
        }))
    };

    handleClickDateRangePickerOpen = () => {
        this.setState(() => ({
            isDateRangePickerOpen: true,
        }))
    };

    handleClickDateRangePickerFilter = () => {
        this.setState(() => ({
            isDateRangePickerOpen: false,
        }));
        this.handleGetAssessments();
        this.handleGetUserProfileAssessmentsCountByRiskRating();
    };

    handleClickDateRangePickerClose = () => {
        this.setState(() => ({
            isDateRangePickerOpen: false,
        }));
    };

    componentDidMount() {
        // ---- Get User Profile ID from URL params & get its details from API ----------------------------------------------
        const {match} = this.props;

        if (match && match.params && match.params.userProfileId) {
            this.setState(() => ({
                userProfileId: match.params.userProfileId,
            }));
            this.handleGetUserProfileDetails();
            this.handleGetUserProfileAssessmentsCountByRiskRating();
        }
    };

    handleGetUserProfileAssessmentsCountByRiskRating = () => {

        this.setState({
            // Clear the data, causing the 'loading icon' to show
            assessmentsCountByRiskRatingForDateRange: null,
        });

        let queryPayload = {
            params: {
                user_id: this.props.match.params.userProfileId,
                start_date: this.state.startDate,
                end_date: this.state.endDate,
            }
        };

        axios.all(
            [
                getUserProfileAssessmentsCountByRiskRatingForDateRange(queryPayload),
            ])
            .then(axios.spread((assessmentsCountByRiskRatingForDateRange) => {

                this.setState({
                    assessmentsCountByRiskRatingForDateRange: assessmentsCountByRiskRatingForDateRange.data,
                });
            }))
    };

    handleGetUserProfileDetails = () => {
        axios.all(
            [
                getUserProfileDetails(this.props.match.params.userProfileId),
            ])
            .then(axios.spread((userProfile) => {
                    this.setState({
                        userProfile: {...userProfile.data},
                        isLoading: false,
                    });
                })
            )
    };

    handleGetAssessments = () => {
        this.setState({
            isLoadingAssessments: true,
        });

        let queryPayload = {
            params: {
                user_id: this.state.userProfileId,
                filter_type: 'UserProfileAssessments',
                start_date: this.state.startDate,
                end_date: this.state.endDate,
            }
        };

        axios.all(
        [
            getAssessments(queryPayload),
        ])
        .then(axios.spread((assessments) => {
                this.setState({
                    assessments: assessments.data.results,
                    isLoadingAssessments: false,
                });
            })
        )
    };

    render() {

        // Display loading icon until all required data has been gathered.
        if (this.state.isLoading) return (<Preloader/>);

        const { classes } = this.props;
        const { assessments, userProfile, startDate, endDate, isDateRangePickerOpen, isLoadingAssessments } = this.state;
        const { assessmentsCountByRiskRatingForDateRange, isLoadingWidgetChartUserProfileAssessmentsByRiskRatingForDateRange } = this.state;

        return (
            <React.Fragment>
                {(isDateRangePickerOpen) &&
                    <DateRangePickerDialog
                        startDate={dayjs(startDate)}
                        endDate={dayjs(endDate)}
                        onStartDateChange={this.handleSetStartDate}
                        onEndDateChange={this.handleSetEndDate}
                        onFilterClick={this.handleClickDateRangePickerFilter}
                        onFilterCloseClick={this.handleClickDateRangePickerClose}
                    />
                }

                <NotificationSnackbar
                    open={this.state.notificationDisplayed}
                    handleClose={this.handleCloseNotification}
                    variant={this.state.notificationVariant}
                    message={this.state.notificationMessage}
                />

                <Grid container spacing={3}>

                    <Grid item xs={6} align={"left"}>
                        <Typography variant="caption">
                            User Profile:&nbsp;
                        </Typography>
                        <Typography variant="h6">
                            {userProfile.fullname} ({userProfile.email})
                            {(!userProfile.is_active) &&
                                <TerminatedUserChip
                                    label={userProfile.termination_date}
                                />
                            }
                        </Typography>
                    </Grid>

                    <Grid item xs={6} align={"right"}>
                        <Typography variant="caption" display={"inline"}>
                            Date Range:&nbsp;
                        </Typography>
                        <Typography variant="subtitle1" display={"inline"}>
                            &nbsp;
                            {moment(startDate).format("ll")} -> {moment(endDate).format("ll")}
                            &nbsp;
                            <Button variant="outlined" color="secondary" onClick={this.handleClickDateRangePickerOpen}>
                                <Edit/>
                            </Button>
                        </Typography>
                    </Grid>


                    <Grid item xs={8}>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} className={classes.root}>
                                <WidgetChartUserProfileAssessmentsByRiskRatingForDateRange
                                    isLoading={isLoadingWidgetChartUserProfileAssessmentsByRiskRatingForDateRange}
                                    data={assessmentsCountByRiskRatingForDateRange}
                                    user={userProfile}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={4}>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} className={classes.root}>
                                <UserProfileManagerWidget
                                    userProfile={userProfile}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} className={classes.root}>
                                <AssessmentsTableUserProfile
                                    userProfile={userProfile}
                                    isLoadingAssessments={isLoadingAssessments}
                                    handleGetAssessments={this.handleGetAssessments}
                                    assessments={assessments}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

            </React.Fragment>
        );
    }
}

UserProfileDetails.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(UserProfileDetails));

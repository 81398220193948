import React from 'react';
import {withRouter} from 'react-router-dom';

// Material UI
import { withStyles } from '@mui/styles'
import {Grid} from '@mui/material';

// Components Shared
import AssessmentsTableBasic from "../molecules/AssessmentsTableBasic";

const styles = theme => ({
    root: {
        paddingRight: theme.spacing(2),
    },
    cardContent: {
        paddingBottom: '16px !important',
    },
    actionContent: {
        margin: '-2px',
    },
    icon: {
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    iconActive: {
        color: theme.palette.primary.main,
    },
    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
});

class WidgetAssessmentsToRevisit extends React.Component {

    render() {
        const { assessmentsData } = this.props;

        return (
            <Grid item xs={12}>
                <AssessmentsTableBasic
                    assessmentsData={assessmentsData}
                    tableTitle={'Assessments To Revisit'}
                    tableTitleTooltipText={
                        <React.Fragment>
                            <b>{'Lists Assessments which you have marked to revisit.'}</b>{' '}
                            <br/>
                            <ul>
                                <li><b>{'Assessments Criteria:'}</b>{' '}
                                    <ul>
                                        <li><b>{'Shift Supervisor = You'}</b>{' '}</li>
                                        <li><b>{'Shift Date = Any date'}</b>{' '}</li>
                                        <li><b>{'Risk Rating = Low, Medium, High'}</b>{' '}</li>
                                        <li><b>{'Action Taken = Includes `Revisit Fatigue Assessment`'}</b>{' '}</li>
                                        <li><b>{'Action Taken Timestamp = Last 24 hours'}</b>{' '}</li>
                                    </ul>
                                </li>
                            </ul>
                        </React.Fragment>
                    }
                />
            </Grid>
        );
    }
}
export default withRouter(withStyles(styles)(WidgetAssessmentsToRevisit));
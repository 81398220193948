import axios from 'axios';
import appConfig from '../common/Config';

// =================================================================================
// User Detail
// =================================================================================
export const getLoggedInUser = () => axios.get(`${appConfig.apiHost}/ffw/me/whoami/`);

// =================================================================================
// User Lookups
// =================================================================================
export const searchUsers = (payload) => axios.get(`${appConfig.apiHost}/ffw/lookups/users/`, payload);

// =================================================================================
// Users Unactioned Assessments
// =================================================================================
export const getMyUnactionedAssessments = () => axios.get(`${appConfig.apiHost}/ffw/me/my_unactioned_assessments/`);

// =================================================================================
// User Management Hierarchy Downwards
// =================================================================================
export const getMyManagementHierarchyDownOrgStructure = () => axios.get(`${appConfig.apiHost}/ffw/me/my_manager_hierarchy_down_org_structure/`);

export const getAssessmentsByHierarchy = (payload) => axios.get(`${appConfig.apiHost}/ffw/assessments/by_manager_hierarchy/`, payload);

export const getMyAssessmentsToRevisit = (payload) => axios.get(`${appConfig.apiHost}/ffw/me/my_assessments_to_revisit/`, payload);
import axios from 'axios';
import appConfig from '../common/Config';

// =================================================================================
// Widgets
// =================================================================================

export const getMyDirectReportsAssessmentsCountByRiskRatingForDateRange = (payload) => axios.get(`${appConfig.apiHost}/ffw/me/my_direct_reports_assessments_count_by_risk_rating_for_date_range/`, payload);

export const getAllReportsAssessmentsCountByRiskRatingForDateRange = (payload) => axios.get(`${appConfig.apiHost}/ffw/me/my_all_reports_assessments_count_by_risk_rating_for_date_range/`, payload);

export const getAssessmentsCountByEmployeeByRiskRatingForSupervisor = (payload) => axios.get(`${appConfig.apiHost}/ffw/me/assessments_count_by_employee_by_risk_rating_for_date_range_for_supervisor/`, payload);

export const getAssessmentsCountByEmployeeByRiskRatingForManager = (payload) => axios.get(`${appConfig.apiHost}/ffw/me/assessments_count_by_direct_reports_by_risk_rating_for_date_range_for_manager/`, payload);

// ===================================================================================

export const getUserProfileAssessmentsCountByRiskRatingForDateRange = (payload) => axios.get(`${appConfig.apiHost}/ffw/widgets/user_profile_assessments_count_by_risk_rating_for_date_range/`, payload);

export const getAssessmentsCountByRiskRatingForDateRange = (payload) => axios.get(`${appConfig.apiHost}/ffw/widgets/assessments_count_by_risk_rating_for_date_range/`, payload);

export const getAssessmentsCountByShiftSupervisorForDateRange = (payload) => axios.get(`${appConfig.apiHost}/ffw/widgets/assessments_count_by_shift_supervisor_for_date_range/`, payload);

export const getAssessmentsCountByEmployeeForDateRange = (payload) => axios.get(`${appConfig.apiHost}/ffw/widgets/assessments_count_by_employee_for_date_range/`, payload);

export const getActionsCountByShiftSupervisorForDateRange = (payload) => axios.get(`${appConfig.apiHost}/ffw/widgets/actions_count_by_shift_supervisor_for_date_range/`, payload);

export const getAssessmentsRequiringActionCountForDateRange = (payload) => axios.get(`${appConfig.apiHost}/ffw/widgets/assessments_requiring_action_count_for_date_range/`, payload);

export const getAssessmentsCountForDateRange = (payload) => axios.get(`${appConfig.apiHost}/ffw/widgets/assessments_count_for_date_range/`, payload);

export const getActionsCountForDateRange = (payload) => axios.get(`${appConfig.apiHost}/ffw/widgets/actions_count_for_date_range/`, payload);

export const getUsersWhoHaveLoggedInCountForDateRange = (payload) => axios.get(`${appConfig.apiHost}/ffw/widgets/users_who_have_logged_in_count_for_date_range/`, payload);
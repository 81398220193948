import React, {Component} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {forwardRef} from 'react';

// Material UI
import { withStyles } from '@mui/styles';
import { Link, Paper,Tooltip, Typography } from '@mui/material';

import {InfoOutlined} from "@mui/icons-material";
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';

// Utils
import MaterialTable from '@material-table/core';

// Components Shared
import Preloader from '../atoms/PreLoader/Preloader';
import RiskRatingScoreChip from '../atoms/RiskRatingScoreChip';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 420,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
});

class AssessmentsTable extends Component {

    render() {
        const { classes, assessmentsData, tableTitle, tableTitleTooltipText } = this.props;

        // Show loading icon while waiting for data
        if (!assessmentsData) return (
            <React.Fragment>
                <Paper>
                    <Preloader/>
                </Paper>
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <MaterialTable
                    columns={[
                        {
                            title: 'Employee',
                            field: 'user_full_name',
                            render: (rowData) => {
                                return rowData.user_full_name + " (" + rowData.user_email + ")"
                            }
                        },
                        {
                            title: 'Risk',
                            field: 'risk_rating',
                            render: (rowData) => {
                                return <RiskRatingScoreChip
                                    size="small"
                                    label={rowData.risk_rating}
                                    RiskRatingName={rowData.risk_rating}
                                    RiskScore={rowData.risk_score}
                                />
                            }
                        },
                        {
                            title: '',
                            field: 'action',  // custom field name (not in data model)
                            render: rowData => {
                                return <Link component={RouterLink} to={"/assessment/" + rowData.id}>View</Link>
                            }
                        },
                    ]}
                    data={assessmentsData}
                    title={
                        <React.Fragment>
                            <div>
                            <Typography variant="h6">
                                {tableTitle}
                                <Tooltip
                                    classes={{
                                        tooltip: classes.htmlTooltip,
                                    }}
                                    title={tableTitleTooltipText}
                                >
                                    <InfoOutlined color='disabled'/>
                                </Tooltip>
                            </Typography>
                            </div>
                        </React.Fragment>
                    }
                    icons={tableIcons}
                    options={{
                        search: false,
                        grouping: false,
                        paging: false,
                        draggable: false,
                        pageSize: 50,
                        pageSizeOptions: [20, 50, 100, 200],
                        emptyRowsWhenPaging: false,
                    }}
                />
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(AssessmentsTable);
import React, {Component} from 'react';
import {Link as RouterLink} from 'react-router-dom';

// Context
import AppContext from '../AppProvider';

// Material UI
import { withStyles } from '@mui/styles';
import { Link, Typography } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';


import { ChevronRight, ExpandMore } from '@mui/icons-material';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
});

class Reports extends Component {
    static contextType = AppContext;

    render() {
        const loggedInUser = this.context.user;
        const {classes} = this.props;

        return (
            <div style={{maxWidth: '100%'}}>
                <Typography className="page-title" variant="h6">Reports</Typography>

                {loggedInUser.is_manager ? (
                    <SimpleTreeView
                        className={classes.root}
                        defaultcollapseicon={<ExpandMore />}
                        defaultexpandicon={<ChevronRight />}
                        defaultexpanded={['1', '2']}
                    >
                        <TreeItem itemId="is-manager-shift-supervisors-reports" nodeid="1" label="Shift Supervisors">
                            <Link component={RouterLink}
                                  to={"/reports/shiftsupervisorassessments"}>
                                Assessments with me specified as Shift Supervisor
                            </Link>
                        </TreeItem>

                        <TreeItem itemId="is-manager-reports" nodeid="2" label="Managers">
                            <Link component={RouterLink}
                                  to={"/reports/assessmentsbyhierarchy"}>
                                Assessments by my management hierarchy
                            </Link>
                            <br/>
                            <Link component={RouterLink}
                                  to={"/reports/directreportassessments"}>
                                Assessments completed by my direct reports
                            </Link>
                        </TreeItem>
                    </SimpleTreeView>
                ) : (
                    <SimpleTreeView
                        className={classes.root}
                        defaultcollapseicon={<ExpandMore />}
                        defaultexpandicon={<ChevronRight />}
                        defaultexpanded={['1']}
                    >
                        <TreeItem itemId="shift-supervisors-reports" nodeid="1" label="Shift Supervisors">
                            <Link component={RouterLink}
                                  to={"/reports/shiftsupervisorassessments"}>
                                Assessments with me specified as Shift Supervisor
                            </Link>
                        </TreeItem>
                    </SimpleTreeView>
                )}

                {loggedInUser.is_app_admin && (
                    <SimpleTreeView
                        className={classes.root}
                        defaultcollapseicon={<ExpandMore />}
                        defaultexpandicon={<ChevronRight />}
                        defaultexpanded={['1']}
                    >
                        <TreeItem itemId="mafat-administrators-only" nodeid="1" label="MAFAT Administrators Only">
                            <Link component={RouterLink}
                                  to={"/reports/summarydata"}>
                                Assessment Submissions Summary (all BHP)
                            </Link>
                        </TreeItem>
                    </SimpleTreeView>
                )}
            </div>


        )
    }
}

export default withStyles(styles)(Reports);
import React from 'react';

import {ErrorMessage} from 'formik';
import {FormHelperText} from '@mui/material';

// Wraps the formik ErrorMessage so it renders a FormHelperText component for rendering errors
const FormErrorMessage = props => {
    const {name} = props;

    return (<ErrorMessage
        name={name}
        render={message => (message && typeof message === "string" &&
            <FormHelperText error={true}>{message}</FormHelperText>)}/>)
};

export default FormErrorMessage;
import React from 'react';
import {withRouter} from 'react-router-dom';

// Material UI
import { withStyles } from '@mui/styles'
import Chip from '@mui/material/Chip';


// Styling overrides - used to override and set the styling/css of the page
const styles = theme => ({
    control: {
        'font-weight': "700",
        'font-family': "Arial, Helvetica, sans-serif",
        'font-size': '11px',
        'border-radius': '5px',
        'borderColor': '#2d3032',
        'color': '#2d3032',
        'background-color': '#81bfdc',
    },
});

class ControlsChip extends React.Component { 

    render() {
        const { classes, label, size } = this.props;
        return (
            <Chip
                size={size}
                label={label}
                className={classes.control}
            />
        );
    }
}

export default withRouter(withStyles(styles)(ControlsChip));
import React from 'react';
import {withRouter} from 'react-router-dom';

// Material UI
import { withStyles } from '@mui/styles'
import {Grid, Typography, Card, CardContent} from '@mui/material';

const styles = theme => ({
    root: {
        paddingRight: theme.spacing(2),
    },
    cardContent: {
        paddingBottom: '16px !important',
    },
    actionContent: {
        margin: '-2px',
    },
    icon: {
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    iconActive: {
        color: theme.palette.primary.main,
    },
    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
});

class UserProfileManagerWidget extends React.Component {

    render() {
        const { classes, userProfile } = this.props;

        return (
            <Grid container justifyContent="center">
                <Grid item xs={12} className={classes.root}>
                    <Card>
                        <CardContent align="left">
                             <Typography variant="h6">
                                Manager: {userProfile.manager_fullname} ({userProfile.manager_email})
                            </Typography>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}
export default withRouter(withStyles(styles)(UserProfileManagerWidget));
import React from 'react';

import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip as ReTooltip,
    XAxis,
    YAxis
} from "recharts";

class CustomizedAxisTick extends React.Component {

    stripDomainFromEmail = (email) => {
        // We use the email as it is unique, but to save space we remove the domain from the email string
        let s = email.substring(0, email.indexOf('@'));
        return(s);
    };

    render() {
        const {x, y, payload,} = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                {/* transform="rotate(-35)"   <- rote the xaxis label 35 degrees so it's angled */}
                <text x={0} y={0} dy={16} fontSize="14" textAnchor="end" fill="#666" transform="rotate(-35)">{this.stripDomainFromEmail(payload.value)}</text>
            </g>
        );
    }
}

// Wraps the formik ErrorMessage so it renders a FormHelperText compoent for rendering errors
const BarChartAssessmentRiskRatings = props => {
    const {data, customised, interval} = props;

    function renderColorfulLegendText(value, entry) {
        return <span style={{fontSize: 14}}>{value}</span>;
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 20, right: 30, left: 20, bottom: 10,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                {customised ? (
                    // interval={0}    <- show all xaxis ticks, do not hide any
                    <XAxis dataKey="name" angle={45} fontSize="10" interval={interval} offset={20} height={90} tick={<CustomizedAxisTick/>}/>
                ):(
                    <XAxis dataKey="name" tick={{fontSize: 14}} interval={interval}/>
                )}
                <YAxis tick={{fontSize: 14}} allowDecimals={false}/>
                <ReTooltip />
                <Legend
                    layout="horizontal"
                    verticalAlign="bottom"
                    height={12}
                    iconType='square'
                    formatter={renderColorfulLegendText}
                />
                <Bar dataKey="Low" stackId="a" fill="#5cb85c" />
                <Bar dataKey="Medium" stackId="a" fill="#f0ad4e" />
                <Bar dataKey="High" stackId="a" fill="#d9534f" />
            </BarChart>
        </ResponsiveContainer>
    )
};

BarChartAssessmentRiskRatings.propTypes = {
    // classes: PropTypes.object.isRequired,
    // theme: PropTypes.object.isRequired,
    // loadOptions: PropTypes.func.isRequired,
    // onChange: PropTypes.func.isRequired,
    // onInputChange: PropTypes.func.isRequired,
    // isMulti: PropTypes.bool,
    // placeholder: PropTypes.string,
    // loadingMessage: PropTypes.string,
    // noOptionsMessage: PropTypes.string,
    // label: PropTypes.string,
    // value: PropTypes.object,
    // // searchType: PropTypes.string,
};

BarChartAssessmentRiskRatings.defaultProps = {
    interval: "preserveEnd",
};

export default BarChartAssessmentRiskRatings;
import React from 'react';
import { withRouter } from 'react-router-dom';

// Material UI
import { withStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';

// Components Shared
import RiskRatingScoreChip from '../atoms/RiskRatingScoreChip';

// Utils
import * as moment from 'moment';

// Styling overrides - used to override and set the styling/css of the page
const styles = theme => ({
});

class UserData extends React.Component {

    render() {

        const { assessment } = this.props;

        return (
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell style={{width: '50%'}}>
                            Who Is Your Shift Supervisor?:
                        </TableCell>

                        <TableCell>
                            { assessment.shift_supervisor_email } ({ assessment.shift_supervisor_full_name})
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{width: '50%'}}>
                            User Being Assessed?:
                        </TableCell>

                        <TableCell>
                            { assessment.user_email } ({ assessment.user_full_name})
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{width: '50%'}}>
                            What Is Your Gender?:
                        </TableCell>

                        <TableCell>
                            { assessment.gender }
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{width: '50%'}}>
                            Which Shift Date Is This Assessment For?:
                        </TableCell>

                        <TableCell>
                            { moment(assessment.shift_start_date).format("MMM Do YYYY") }
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{width: '50%'}}>
                            What Is You Shift Start Time?:
                        </TableCell>

                        <TableCell>
                            { assessment.shift_start_time }
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{width: '50%'}}>
                            Sign-On-Location:
                        </TableCell>

                        <TableCell>
                            { assessment.depot }
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{width: '50%'}}>
                            Risk Rating:
                        </TableCell>

                        <TableCell>
                            <RiskRatingScoreChip
                                size="small"
                                label={assessment.risk_rating}
                                RiskRatingName={assessment.risk_rating}
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{width: '50%'}}>
                            Timestamp:
                        </TableCell>

                        <TableCell>
                            { moment(assessment.timestamp).format("MMMM Do YYYY, h:mm:ss a") }
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }
};

export default withRouter(withStyles(styles)(UserData));
import React from 'react';
import {ReactComponent as BHPLogoDev} from '../../assets/icons/bhplogo_dev.svg';
import {ReactComponent as BHPLogoTest} from '../../assets/icons/bhplogo_test.svg';
import {ReactComponent as BHPLogoQA} from '../../assets/icons/bhplogo_qa.svg';
import {ReactComponent as BHPLogoProd} from '../../assets/icons/bhplogo_prod.svg';
import {ReactComponent as BHPLogoUnknown} from '../../assets/icons/bhplogo_unknown.svg';


const BHPLogo =  ({width, height, color, ...otherProps}) => {
    width = width || 74;
    height = height || (width * 28 / 74);

    const handleBHPLogo = props => {

        let env;
        env = window.env.ADAL_CONFIG.app_env;

        if (env === "dev") {
            return <BHPLogoDev/>

        } else if (env === "test") {
            return <BHPLogoTest/>

        } else if (env === "qa") {
            return <BHPLogoQA/>

        } else if (env === "prod") {
            return <BHPLogoProd/>

        } else {
            return <BHPLogoUnknown/>
        }
    };

    return (
        handleBHPLogo()
    );
}

export default BHPLogo;
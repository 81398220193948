import React from 'react';
import {withRouter} from 'react-router-dom';

// Material UI
import { withStyles } from '@mui/styles'
import Chip from '@mui/material/Chip';

// Styling overrides - used to override and set the styling/css of the page
const styles = theme => ({
    manager: {
        'font-weight': "700",
        'font-family': "Arial, Helvetica, sans-serif",
        'font-size': '11px',
        'border-radius': '5px',
        'borderColor': '#54bfd9',
        'color': '#ffffff',
        'background-color': '#d8581f',
    },
});

class ManagerChip extends React.Component {

    render() {

        const { classes, label, size } = this.props;

        const getChipStyle = () => {
            let chipClass;

            chipClass = classes.manager;

            return chipClass
        };

        const getLabelText = () => {
            let labelText = '';

            labelText = label;
            labelText = labelText.toUpperCase();

            return labelText
        };

        return (
            <Chip
                size={size}
                label={getLabelText()}
                className={getChipStyle()}
            />
        );
    }
};

export default withRouter(withStyles(styles)(ManagerChip));